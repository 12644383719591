<horgasz-simple-header title="Fogási napló" [backButton]="false"></horgasz-simple-header>
<ion-content>
  <ion-tabs>
    <ion-tab-bar slot="top">
      <!-- tab -->
      <ion-tab-button tab="horgasznapok">
        <ion-label>Horgásznapok</ion-label>
      </ion-tab-button>
      <!-- tab -->
      <ion-tab-button tab="fogasok">
        <ion-label>Fogások</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-content>
