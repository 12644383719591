import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { FogasDatePipe } from 'app/altalanos/pipes/fogas-date.pipe';
import { VizteruletPipe } from 'app/altalanos/pipes/vizterulet.pipe';

import { MaterialModule } from 'app/material/material.module';
import { HorgaszatokAdottNaponModalPage } from './horgaszatok-adott-napon-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MaterialModule,
    FogasDatePipe,
    VizteruletPipe
  ],
  declarations: [HorgaszatokAdottNaponModalPage]
})
export class HorgaszatokAdottNaponModalPageModule {}
