<div class="modal-content" *ngIf="form && data">
  <ion-card class="modal-rogzites">

    <ion-card-header class="ion-text-center">
      <ion-item lines="none" tabindex="-1">
        <ion-label>Fogás rögzítése</ion-label>
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()">
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
      <ion-text color="danger" class="custom-warning">
        <div style="margin-left: 30px; margin-right: 30px">
          <ion-icon name="alert-circle"></ion-icon>
          Rögzítés után nincs lehetőség a fogás módosítására vagy törlésére.
        </div>
      </ion-text>
    </ion-card-header>

    <ion-card-content *ngIf="fogasRogzitesStep === FogasRogzitesStep.SZERKESZTES">

      <mat-error class="align-center">
        <span *ngIf="form.errors?.server">{{form.errors.server.message}}</span>
      </mat-error>

      <form [formGroup]="form">
        <!-- input -->
        <table>
          <tr>
            <td nowrap>
              <mat-form-field class="mandatory-field">
                <input class="custom-input" type="text" name="idopontDatum" formControlName="idopontDatum"
                       matInput readonly
                       placeholder="Fogás időpont"
                       [matDatepicker]="pickerFogas"
                       [min]="data.ervenyessegKezdete"
                       [max]="data.ervenyessegVege">
<!--                <mat-datepicker-toggle matSuffix [for]="pickerFogas">-->
<!--                  <mat-icon matDatepickerToggleIcon svgIcon="moh-icon-calendar"></mat-icon>-->
<!--                </mat-datepicker-toggle>-->
                <mat-datepicker #pickerFogas></mat-datepicker>
                <mat-error>
                  <horgasz-form-field-error-content [control]="form.controls.idopontDatum"></horgasz-form-field-error-content>
                </mat-error>
              </mat-form-field>
            </td>
            <td nowrap style="width: 20%;">
              <mat-form-field class="mandatory-field">
                <input class="custom-input" matInput readonly type="number" min="0" max="24" name="idopontOra" placeholder="Óra" formControlName="idopontOra">
                <mat-error>
                  <horgasz-form-field-error-content [control]="form.controls.idopontOra"></horgasz-form-field-error-content>
                </mat-error>
              </mat-form-field>
            </td>
            <td nowrap style="width: 20%;">
              <mat-form-field class="mandatory-field">
                <input class="custom-input" matInput readonly type="number" min="0" max="60" name="idopontPerc" placeholder="Perc" formControlName="idopontPerc">
                <mat-error>
                  <horgasz-form-field-error-content [control]="form.controls.idopontPerc"></horgasz-form-field-error-content>
                </mat-error>
              </mat-form-field>
            </td>
          </tr>
        </table>

        <!-- input -->
        <ion-item lines="none" class="mandatory-field has-ng-select">
          <ion-label position="stacked">Vízterület</ion-label>
          <div class="custom-input vizterulet">{{data.selectedVizterulet.id | vizterulet}}</div>
        </ion-item>
        <ion-note slot="error" color="danger">
          <horgasz-form-field-error-content [control]="form.controls[AFR('vizteruletId')]"></horgasz-form-field-error-content>
        </ion-note>

        <!-- input -->
        <ion-item lines="none" class="mandatory-field has-ng-select">
          <ion-label position="stacked">Halfaj</ion-label>
          <div class="ng-select-field">
            <ng-select class="moh-ng-select"
                       [clearable]="false"
                       [formControlName]="AFR('halfajId')"
                       [virtualScroll]="true"
                       [items]="halfajok">
            </ng-select>
          </div>
        </ion-item>
        <ion-note slot="error" color="danger">
          <horgasz-form-field-error-content [control]="form.controls[AFR('halfajId')]"></horgasz-form-field-error-content>
        </ion-note>
          <!-- input -->
          <ion-item class="mandatory-field">
            <ion-label position="stacked">Tömeg (kg)</ion-label>
            <input inputmode="decimal" class="custom-input"
                   [maskito]="maskitoOptions"
                   [formControlName]="AFR('suly')">
          </ion-item>
          <ion-note slot="error" color="danger">
            <horgasz-form-field-error-content [control]="form.controls[AFR('suly')]"></horgasz-form-field-error-content>
          </ion-note>
      </form>

      <div class="ion-text-center ion-padding-top">
        <ion-button class="ion-margin-top"
                    (click)="onFogasMegerositesClick()"
                    [disabled]="form.invalid">
          Tovább
        </ion-button>
      </div>

    </ion-card-content>

    <ion-card-content *ngIf="fogasRogzitesStep === FogasRogzitesStep.MEGEROSITES">
      <table>
        <tr>
          <td nowrap>
            <p>Fogás időpont</p>
            <span class="custom-input">{{form.controls.idopontDatum.value | fogasDate: 'timestamp'}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap>
            <p>Vízterület</p>
            <span class="custom-input" style="text-wrap: auto">{{forecastFogasRequest.vizteruletId | vizterulet}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap>
            <p>Halfaj</p>
            <span class="custom-input">{{forecastFogasRequest.halfajId | halfaj}}</span>
          </td>
        </tr>
        <tr>
          <td nowrap>
            <p>Tömeg (kg)</p>
            <span class="custom-input">{{forecastFogasRequest.suly | fogasSuly}}</span>
          </td>
        </tr>
      </table>

      <ng-container *ngIf="fogasValidacioResponse.fogasValidacioResultList.length > 0">
        <ion-grid>
            <ng-container *ngFor="let result of fogasValidacioResponse.fogasValidacioResultList">
              <ng-container [ngSwitch]="result">
                <ion-row *ngSwitchCase="FogasValidacioResult.NAPI_DB_TULLEPES">
                  <ion-col>
                    <ion-list lines="none">
                      <ion-item>
                        Túllépte a napi maximális darabszámot: {{fogasValidacioResponse.rogzitesiSzabaly.napiDarabLimit}}
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </ion-row>

                <ion-row *ngSwitchCase="FogasValidacioResult.NAPI_MAXDB_TULLEPES">
                  <ion-col>
                    <ion-list lines="none">
                      <ion-item>
                        Túllépte a napi darabszám-korlátozással védett halfajokból a max.
                        {{fogasValidacioResponse.rogzitesiSzabaly.maxNapiDarabLimit}} db elvihető mennyiséget
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </ion-row>

                <ion-row *ngSwitchCase="FogasValidacioResult.NAPI_SULY_TULLEPES">
                  <ion-col>
                    <ion-list lines="none">
                      <ion-item>
                        Túllépte a napi maximális súlyhatárt: {{fogasValidacioResponse.rogzitesiSzabaly.napiSulyLimit}} kg
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </ion-row>

                <ion-row *ngSwitchCase="FogasValidacioResult.NAPI_MAXSULY_TULLEPES">
                  <ion-col>
                    <ion-list lines="none">
                      <ion-item>
                        Túllépte a napi súlykorlátozással védett halfajokból a max.
                        {{fogasValidacioResponse.rogzitesiSzabaly.maxNapiSulyLimit}} kg elvihető mennyiséget
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </ion-row>

                <ion-row *ngSwitchCase="FogasValidacioResult.TILALMI_IDOSZAK">
                  <ion-col>
                    <ion-list lines="none">
                      <ion-item>
                        <ion-icon src="/assets/icon/warning.svg"></ion-icon> Tilalmi időszak:
                        {{fogasValidacioResponse.tilalmiIdoszak.kezdete | fogasDate: 'date'}} -
                        {{fogasValidacioResponse.tilalmiIdoszak.vege | fogasDate: 'date'}}
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </ion-row>

                <ion-row *ngSwitchCase="FogasValidacioResult.SULY_LIMITEN_KIVUL">
                  <ion-col>
                    <ion-list lines="none">
                      <ion-item>
                        Rögzítési súlykorlátozás:
                        {{fogasValidacioResponse.rogzitesiSzabaly.sulyLimitMin | fogasSuly}} kg -
                        {{fogasValidacioResponse.rogzitesiSzabaly.sulyLimitMax | fogasSuly}} kg
                      </ion-item>
                    </ion-list>
                  </ion-col>
                </ion-row>
              </ng-container>
            </ng-container>

            <ion-row *ngIf="fogasValidacioResponse.fogasValidacioResultList.length > 0 && fogasValidacioResponse.rogzitesEngedelyezett">
              <ion-col>
                <ion-list lines="none">
                  <ion-item>
                    Biztosan folytatni kívánja a rögzítést?
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>

            <ion-row *ngIf="!fogasValidacioResponse.rogzitesEngedelyezett">
              <ion-col>
                <ion-list lines="none">
                  <ion-item>
                    <ion-text color="danger">
                      A rögzítés nem engedélyezett!
                    </ion-text>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
      </ng-container>

      <div class="ion-text-center ion-padding-top">
        <ion-button class="ion-margin-top" fill="outline"
                    (click)="onMegerositesVisszaClick()"
                    [disabled]="isRogzitesInProgress">
          Vissza
        </ion-button>

        <ion-button *ngIf="fogasValidacioResponse.rogzitesEngedelyezett"
                    [disabled]="isRogzitesInProgress" class="ion-margin-top"
                    (click)="onFogasRogzitesClick()">
          Rögzítés
          <ion-spinner *ngIf="isRogzitesInProgress"></ion-spinner>
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</div>
