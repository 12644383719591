import { Component, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellCssClasses, MatCalendarUserEvent } from '@angular/material/datepicker';
import { ModalController } from '@ionic/angular';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ElektronikusFogasNyilvantartas, HorgaszattalToltottNap, VizteruletTorzsadat } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { Utils } from 'app/core/utils';
import { AktualisHorgaszatService } from 'app/fogasi-naplo/aktualis-horgaszat.service';
import { FogasiNaploService } from 'app/services/fogasi-naplo.service';
import { LocationService } from 'app/services/location.service';
import { ToastService } from 'app/services/toast.service';
import { TorzsadatProviderService } from 'app/services/torzsadat-provider.service';
import { LastModifiedType } from 'app/shared/last-modified-refresher/last-modified-type';
import { HorgaszatMegkezdeseModalData } from './horgaszat-megkezdese-modal/horgaszat-megkezdese-modal-data';
import { HorgaszatMegkezdeseModalPage } from './horgaszat-megkezdese-modal/horgaszat-megkezdese-modal.page';
import { HorgaszatokAdottNaponModalData } from './horgaszatok-adott-napon-modal/horgaszatok-adott-napon-modal-data';
import { HorgaszatokAdottNaponModalPage } from './horgaszatok-adott-napon-modal/horgaszatok-adott-napon-modal.page';

@Component({
  selector: 'horgasz-horgasznapok',
  templateUrl: './horgasznapok-page.component.html',
  styleUrls: ['./horgasznapok-page.component.scss'],
})
export class HorgasznapokPage extends OnDestroyMixin{

  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  eFogasNyilvantartas: ElektronikusFogasNyilvantartas;
  vizteruletList: Array<VizteruletTorzsadat>;

  selectedDate = null;
  minDate: Date;
  maxDate: Date;
  horgaszattalToltottNapok: Array<HorgaszattalToltottNap>;

  dateClassVar = this.dateClass();

  selectedVizterulet: VizteruletTorzsadat;

  protected readonly LastModifiedType = LastModifiedType;

  constructor(
    public modalController: ModalController,
    protected authService: AuthService,
    private fogasiNaploService: FogasiNaploService,
    private toastService: ToastService,
    private locationService: LocationService,
    private torzsadatProviderService: TorzsadatProviderService,
    private aktualisHorgaszatService: AktualisHorgaszatService,
  ) {
    super();

    // vizteruletek
    this.torzsadatProviderService.vizteruletList.pipe(untilComponentDestroyed(this)).subscribe(
      result => this.vizteruletList = result
    );

    this.fogasiNaploService.enaploNyilvantartas.pipe(untilComponentDestroyed(this)).subscribe(result =>
      this.updateFromDomain(result)
    );

    //Horgaszat megkezdese
    this.aktualisHorgaszatService.aktualisHorgaszatDataObservable.pipe(untilComponentDestroyed(this)).subscribe(result =>
      this.selectedVizterulet = result.selectedVizterulet
    );
  }

  ionViewDidEnter(){
    this.aktualisHorgaszatService.refresh();
    this.locationService.refresh();
    this.fogasiNaploService.refresh();
  }

  onLastModifierRefreshEvent(){
    this.fogasiNaploService.refresh();
  }

  dateSelected(event: MatCalendarUserEvent<Date>) {
    if (this.horgaszattalToltottNapok) {
      const selectedHNap = this.horgaszattalToltottNapok.find(hNap =>
        Utils.equalsDate(new Date(hNap.datum), event.value)
      );
      if(selectedHNap){
        //csak akkor nyitjuk meg a modalt ha van horgaszat
        const data: HorgaszatokAdottNaponModalData = { horgaszattalToltottNap: selectedHNap, vizteruletList: this.vizteruletList };
        this.modalController.create({
          component: HorgaszatokAdottNaponModalPage,
          componentProps: { data },
          cssClass: 'horgaszatok-adott-napon-modal',
        }).then(modalEl => {
          modalEl.present();
          return modalEl.onDidDismiss().then(() => this.selectedDate = undefined);
        });
      }
    }
  }

  onHorgaszatMegkezdeseClick() {
    const data: HorgaszatMegkezdeseModalData = {
      vizteruletList: this.vizteruletList
    };
    this.modalController.create({
      component: HorgaszatMegkezdeseModalPage,
      componentProps: { data },
      cssClass: 'horgaszat-megkezdese-modal',
    }).then(modalEl => {
      modalEl.present();
      return modalEl.onDidDismiss();
    });
  }

  private updateFromDomain(result: ElektronikusFogasNyilvantartas){
    //enaploNyilvantartas gyorsabb mint a calendar megjelenitese
    setTimeout(() => {
      this.minDate = Utils.fromServerDate(result.ervenyessegKezdete);
      this.maxDate = Utils.fromServerDate(result.ervenyessegVege);
      this.eFogasNyilvantartas = result;
      this.horgaszattalToltottNapok = result.horgaszattalToltottNapok;
      this.calendar.updateTodaysDate(); // frissitjuk a naptarban a megjelolt napokat (calendar.dateClass)
    });
  }

  private dateClass() {
    return (inputDate: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.horgaszattalToltottNapok?.some(hNap =>
        Utils.equalsDate(new Date(hNap.datum), inputDate)
      );
      return highlightDate ? 'moh-horgasz-nap' : undefined;
    };
  }
}
