import { Component } from '@angular/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { HorgaszturizmusPoiItem, MobilAppControllerService } from 'api';
import { TimerMutex } from 'app/core/timer-mutex';
import { LocationService } from 'app/services/location.service';
import { ToastService } from 'app/services/toast.service';


@Component({
  selector: 'horgasz-lista-nezet',
  templateUrl: './lista-nezet.page.html',
  styleUrls: ['./lista-nezet.page.scss'],
})
export class ListaNezetPage extends OnDestroyMixin {

  poiList: Array<HorgaszturizmusPoiItem>;
  isLoading = true;

  private timerMutex = new TimerMutex(1);

  constructor(
    private locationService: LocationService,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService
  ){
    super();
  }

  ionViewDidEnter(){
    this.refreshContent();
  }

  refreshContent(){
    this.timerMutex.runExclusive(() => {
      this.loadPoiList();
    });
  }

  async loadPoiList() {
    this.isLoading = true;
    try {
      const locationPos = await this.locationService.getLocation();
      this.poiList = await this.mobilAppControllerService.findNearbyPoiItems(locationPos).toPromise();
    } catch (error) {
      //await this.toastService.httpError(error);
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }
}
