import { PipeTransform } from '@angular/core';

export class SelectableItem {
  id: any;
  nev: any;
  priority: number;

  constructor(value, pipe: PipeTransform) {
    this.id = value;
    this.nev = pipe.transform(value);
  }

  static of(id: any, nev: any, priority: number): SelectableItem{
    return { id, nev, priority };
  }

  static sort(selectableItems: Array<SelectableItem>): SelectableItem[] {
    return selectableItems.sort((si1, si2) => {
      if (si1.priority !== si2.priority) {
        return si1.priority - si2.priority;
      }
      return SelectableItem.removeNonAlphanumeric(si1.nev)
        .localeCompare(SelectableItem.removeNonAlphanumeric(si2.nev));
    });
  }

  private static removeNonAlphanumeric(value: string): string {
    return value.replace(/[^a-zA-Z0-9]/g, '');
  }
}
