<horgasz-simple-header title="Horgász okmánytárca" [backButton]="false"></horgasz-simple-header>

<ion-content>
  <ion-tabs>
    <ion-tab-bar slot="top">
      <!-- tab -->
      <ion-tab-button onClickScroll tab="horgaszkartya">
        <ion-label>Horgászkártya</ion-label>
      </ion-tab-button>
      <!-- tab -->
      <ion-tab-button onClickScroll tab="jegyek">
        <ion-label>Jegyeim</ion-label>
      </ion-tab-button>
      <!-- tab -->
      <ion-tab-button onClickScroll tab="nyilatkozatok">
        <ion-label>Nyilatkozatok</ion-label>
      </ion-tab-button>
      <!-- tab -->
      <ion-tab-button onClickScroll tab="horgaszvizsga">
        <ion-label>Horgászvizsga</ion-label>
      </ion-tab-button>
      <!-- tab -->
      <ion-tab-button onClickScroll tab="versenyzoi-engedely">
        <ion-label>Versenyzői engedély</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-content>

