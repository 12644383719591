import { Injectable } from '@angular/core';
import { HorgasztatasSzolgaltatasBundle, MobilAppControllerService } from 'api';
import { ToastService } from 'app/services/toast.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HorgasztatasService {

  cachedHorgasztatasBundle: HorgasztatasSzolgaltatasBundle;

  bundleLoading: Observable<boolean>;
  private bundleLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService
  ) {
    this.bundleLoadingSubject = new BehaviorSubject<boolean>(true);
    this.bundleLoading = this.bundleLoadingSubject.asObservable();
  }

  async getBundle(szolgaltatasId?: number): Promise<HorgasztatasSzolgaltatasBundle> {
    if (this.cachedHorgasztatasBundle?.szolgaltatasId === szolgaltatasId) {
      return this.cachedHorgasztatasBundle;
    } else {
      try {
        this.bundleLoadingSubject.next(true);
        this.cachedHorgasztatasBundle = await this.mobilAppControllerService.horgasztatasReszletek({id: szolgaltatasId}).toPromise();
        return this.cachedHorgasztatasBundle;
      } catch (error) {
        //this.toastService.httpError(error);
      } finally {
        this.bundleLoadingSubject.next(false);
      }
    }
  }
}
