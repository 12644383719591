import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';
import { VersionComponent } from 'app/altalanos/version/version.component';
import { ErrorMessageComponent } from 'app/core/server-error-message/error-message.component';

import {AuthPageRoutingModule} from './auth-routing.module';

import {AuthPage} from './auth.page';
import { AutofillDirective } from './autofill.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AuthPageRoutingModule,
    ErrorMessageComponent,
    VersionComponent,
  ],
  declarations: [AuthPage, AutofillDirective]
})
export class AuthPageModule {}
