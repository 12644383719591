export class LocalStoreKey {
  static readonly USERSESSION = 'KEY_USERSESSION';
  static readonly SSSUSER = 'KEY_SSSUSER';
  static readonly FELHASZNALO = 'KEY_FELHASZNALO';

  static readonly POSTAFIOK_SZAMOSSAG = 'postafiok-szamossag';
  static readonly POSTAFIOK_LISTAZAS = 'postafiok-listazas';

  static readonly BASE64_IMAGE_PREFIX = 'base64image';

  static readonly TORZSADAT_BUNDLE = 'fetchAllTorzsadat';
  static readonly TORZSADAT_HORGASZTATAS = 'horgasztatasTorzsadat';
  static readonly TORZSADAT_VIZTERULETEK = 'vizteruletList';
  static readonly TORZSADAT_HALFAJOK = 'halfajList';

  static readonly FOGASI_NAPLO_PREFIX = 'fogasiNaplo';
  static readonly AKTUALIS_HORGASZAT_PREFIX = 'aktualishorgaszat';

  static readonly DEVICE_REGISTRATION_PREFIX = 'device-registration';

  static readonly KEEP_KEY_PREFIXES = [
    LocalStoreKey.FOGASI_NAPLO_PREFIX, LocalStoreKey.AKTUALIS_HORGASZAT_PREFIX,
    LocalStoreKey.TORZSADAT_BUNDLE, LocalStoreKey.TORZSADAT_HORGASZTATAS,
    LocalStoreKey.TORZSADAT_VIZTERULETEK, LocalStoreKey.TORZSADAT_HALFAJOK,
    LocalStoreKey.DEVICE_REGISTRATION_PREFIX,
  ];
}
