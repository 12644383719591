import { Component, NgZone } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

import { ElektronikusFogasNyilvantartas, Halfaj, VizteruletTorzsadat } from 'api';
import { AktualisHorgaszatService } from 'app/fogasi-naplo/aktualis-horgaszat.service';
import { FogasokGroup } from 'app/fogasi-naplo/fogasok/fogasok-group';
import { FogasRogzitesModalData } from 'app/fogasi-naplo/fogasok/rogzites-modal/fogas-rogzites-modal-data';
import { FogasRogzitesModalPage } from 'app/fogasi-naplo/fogasok/rogzites-modal/fogas-rogzites-modal.page';
import { FogasiNaploService } from 'app/services/fogasi-naplo.service';
import { LocationService } from 'app/services/location.service';
import { ToastService } from 'app/services/toast.service';
import { TorzsadatProviderService } from 'app/services/torzsadat-provider.service';
import { LastModifiedType } from 'app/shared/last-modified-refresher/last-modified-type';

@Component({
  selector: 'horgasz-fogasok',
  templateUrl: './fogasok.page.html',
  styleUrls: ['./fogasok.page.scss']
})
export class FogasokPage extends OnDestroyMixin {

  readonly LastModifiedType = LastModifiedType;

  enaploNyilvantartas: ElektronikusFogasNyilvantartas;
  halfajList: Array<Halfaj>;
  vizteruletList: Array<VizteruletTorzsadat>;

  selectedVizterulet: VizteruletTorzsadat;

  fogasokGroup: Array<FogasokGroup>;

  isSmallDisplay: boolean;

  constructor(
    private toastService: ToastService,
    // private displayObserverService: DisplayObserverService,
    private modalController: ModalController,
    private torzsadatProviderService: TorzsadatProviderService,
    private fogasiNaploService: FogasiNaploService,
    private locationService: LocationService,
    private aktualisHorgaszatService: AktualisHorgaszatService,
    private ngZone: NgZone
  ) {
    super();

    // halfajok
    this.torzsadatProviderService.halfajList.pipe(untilComponentDestroyed(this)).subscribe(
      result => this.halfajList = result
    );
    // vizteruletek
    this.torzsadatProviderService.vizteruletList.pipe(untilComponentDestroyed(this)).subscribe(
      result => this.vizteruletList = result
    );

    this.fogasiNaploService.enaploNyilvantartas.pipe(untilComponentDestroyed(this)).subscribe(result =>
      this.updateFromDomain(result)
    );

    // kepernyo figyeles
    // this.displayObserverService.displaySize.pipe(untilComponentDestroyed(this)).subscribe(size => {
    //   this.isSmallDisplay = size.isSmall();
    // });

    //Horgaszat megkezdese
    this.aktualisHorgaszatService.aktualisHorgaszatDataObservable.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.ngZone.run(() => {
        //ha platform resumeot kovetoen jon az info akkor csak igy frissul az angular
        this.selectedVizterulet = result.selectedVizterulet;
      });
    });
  }

  ionViewDidEnter(){
    this.aktualisHorgaszatService.refresh();
    this.locationService.refresh();
    this.fogasiNaploService.refresh();
  }

  onFogasRogzitesClick() {
    this.openFogasRogzitesModal();
  }

  openFogasRogzitesModal() {
    const data = new FogasRogzitesModalData(
      this.fogasiNaploService.ervenyesFogasiNaplo.sorszam,
      this.enaploNyilvantartas,
      this.halfajList,
      this.selectedVizterulet
    );
    this.modalController.create({
      component: FogasRogzitesModalPage,
      componentProps: { data },
      cssClass: 'fogas-rogzites-modal',
    }).then(modalEl => {
      modalEl.present();
      return modalEl.onDidDismiss();
    });
  }

  onLastModifierRefreshEvent(){
    this.fogasiNaploService.refresh();
  }

  private updateFromDomain(result: ElektronikusFogasNyilvantartas){
    this.enaploNyilvantartas = result;
    if (result.fogasok.length) {
      // ev-honap csoportkepzes
      this.fogasokGroup = FogasokGroup.buildGroup(result.fogasok);
    }
  }

}
