import { Injectable } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { AddFogasRequest, ElektronikusFogas, ElektronikusFogasNyilvantartas, FogasiNaplo, FogasNyilvantartasRequest, HorgaszNapRequest, MobilAppControllerService, VizteruletHorgaszat } from 'api';
import { LocalStoreKey } from 'app/core/local-store-key';
import { TimerMutex } from 'app/core/timer-mutex';
import { Utils } from 'app/core/utils';
import { LocationService } from 'app/services/location.service';
import { Mutex } from 'async-mutex';
import { uniqBy } from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CacheService } from './cache.service';

export interface LocalFogasNyilvantartas {
  enaploNyilvantartas: ElektronikusFogasNyilvantartas;
  addFogasRequestList: Array<AddFogasRequest>;
  horgaszNapRequestList: Array<HorgaszNapRequest>;
}

@Injectable({
  providedIn: 'root'
})
export class FogasiNaploService extends OnDestroyMixin {

  ervenyesFogasiNaplo: FogasiNaplo;

  refreshedDate: Observable<Date>;
  enaploNyilvantartas: Observable<ElektronikusFogasNyilvantartas>;

  private refreshedDateSubject = new BehaviorSubject<Date>(null);
  private enaploNyilvantartasSubject = new BehaviorSubject<ElektronikusFogasNyilvantartas>(null);

  private refreshTimerMutex = new TimerMutex(1);
  private serverRefreshTimerMutex = new TimerMutex(3);
  private localFogasNyilvantartasMutex = new Mutex();

  private syncInProgress = false;

  constructor(
    private authService: AuthService,
    private mobilAppControllerService: MobilAppControllerService,
    private cacheService: CacheService,
    private locationService: LocationService,
  ) {
    super();

    this.refreshedDate = this.refreshedDateSubject.asObservable();
    this.enaploNyilvantartas = this.enaploNyilvantartasSubject.asObservable().pipe(
      filter(value => !!value)
    );

    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(async auth => {
      if(auth.felhasznalo.ervenyesFogasiNaplo?.elektronikus){
        this.ervenyesFogasiNaplo = auth.felhasznalo.ervenyesFogasiNaplo;
        this.getLocalFogasNyilvantartas().then(() => {
          this.refresh();
          this.nextRefreshedDate();
        });
      }
    });
  }

  refresh() {
    //local fogas frissitese miatt kell
    this.refreshEnaploNyilvantartas();
    this.refreshTimerMutex.runExclusive(async () => {
      if(this.ervenyesFogasiNaplo && !this.syncInProgress){
        this.syncInProgress = true;
        try {
          const refreshedNapok = await this.syncHorgaszNapRequests();
          const refreshedFogasok = await this.syncLocalFogasok();
          if(refreshedNapok || refreshedFogasok){
            await this.refreshEnaploNyilvantartas();
          }
        } catch (err) {
        } finally {
          this.syncInProgress = false;
        }
      }
    });
  }

  async enaploAddFogas(request: AddFogasRequest) {
    request.gpsKoordinata = this.locationService.lastGpsKoordinata;
    await this.addFogasToStorage(request);
    this.refresh();
  }

  async enaploAddHorgaszNap(request: HorgaszNapRequest) {
    request.gpsKoordinata = this.locationService.lastGpsKoordinata;
    await this.addHorgaszNapToStorage(request);
    this.refresh();
  }

  private getCacheId(){
    return this.ervenyesFogasiNaplo ? LocalStoreKey.FOGASI_NAPLO_PREFIX + this.ervenyesFogasiNaplo.sorszam : null;
  }

  private async getLocalFogasNyilvantartas(){
    let localFogasNyilvantartas: LocalFogasNyilvantartas =  await this.cacheService.get(this.getCacheId());
    if(!localFogasNyilvantartas){
      localFogasNyilvantartas = {
        enaploNyilvantartas: undefined,
        addFogasRequestList: [],
        horgaszNapRequestList: []
      } as LocalFogasNyilvantartas;
      await this.cacheService.set<LocalFogasNyilvantartas>(this.getCacheId(), localFogasNyilvantartas);
    }
    return localFogasNyilvantartas;
  }

  private async saveLocalFogasNyilvantartas(localFogasNyilvantartas: LocalFogasNyilvantartas){
    await this.cacheService.set(this.getCacheId(), localFogasNyilvantartas);
  }

  private async refreshEnaploNyilvantartas(){
    if (this.ervenyesFogasiNaplo?.elektronikus) {
      const localFogasNyilvantartas = await this.getLocalFogasNyilvantartas();
      if(localFogasNyilvantartas.enaploNyilvantartas){
        await this.publishEnaploNyilvantartas(localFogasNyilvantartas.enaploNyilvantartas);
      }
      this.serverRefreshTimerMutex.runExclusive(async () => {
        try {
          const request: FogasNyilvantartasRequest = {fogasiNaploId: this.ervenyesFogasiNaplo.sorszam};
          const enaploNyilvantartas = await this.mobilAppControllerService.enaploLekerdezes(request).toPromise();
          await this.addEnaploNyilvantartasToStorage(enaploNyilvantartas);
          await this.publishEnaploNyilvantartas(enaploNyilvantartas);
          this.nextRefreshedDate();
        } catch (err) {}
      });
    }
  }

  private async publishEnaploNyilvantartas(enaploNyilvantartas: ElektronikusFogasNyilvantartas){
    //local fogasok
    const localFogasNyilvantartas = await this.getLocalFogasNyilvantartas();
    const localFogasok = localFogasNyilvantartas.addFogasRequestList.map(addFogasRequest => {
      const elektronikusFogas: ElektronikusFogas = {
        halfajId: addFogasRequest.halfajId,
        suly: addFogasRequest.suly,
        vizteruletId: addFogasRequest.vizteruletId,
        idopont: addFogasRequest.idopont,
        uuid: addFogasRequest.uuid
      };
      return elektronikusFogas;
    });
    enaploNyilvantartas.fogasok = enaploNyilvantartas.fogasok.concat(localFogasok);
    enaploNyilvantartas.fogasok = uniqBy<ElektronikusFogas>(enaploNyilvantartas.fogasok, 'uuid');

    //local horgasz napok
    const localHorgaszattalToltottNapok = await this.localToHorgaszattalToltottNapMap();
    localHorgaszattalToltottNapok.forEach((localVizteruletHorgaszatok, fogasNap) => {
      const vizteruletHorgaszatok = enaploNyilvantartas.horgaszattalToltottNapok
        .find(htn => htn.datum === fogasNap);
      if(vizteruletHorgaszatok){
        vizteruletHorgaszatok.horgaszatList = vizteruletHorgaszatok.horgaszatList.concat(localVizteruletHorgaszatok);
      } else {
        enaploNyilvantartas.horgaszattalToltottNapok.push({ datum: fogasNap, horgaszatList: localVizteruletHorgaszatok });
      }
    });
    this.enaploNyilvantartasSubject.next(enaploNyilvantartas);
  }

  private async localToHorgaszattalToltottNapMap(){
    const localFogasiNaplo = await this.getLocalFogasNyilvantartas();
    const localHorgasznapRequests = localFogasiNaplo.horgaszNapRequestList;
    const fogasDateFormat = 'YYYY-MM-DD';
    const horgaszattalToltottNapMap: Map<string, Array<VizteruletHorgaszat>> = new Map();
    Utils.distinct(localHorgasznapRequests.map(addFogasRequest =>
      moment(addFogasRequest.megkezdes).format(fogasDateFormat)
    )).forEach(date => horgaszattalToltottNapMap.set(date, []));
    localHorgasznapRequests.map(horgaszNapRequest => {
      const fogasNap = moment(horgaszNapRequest.megkezdes).format(fogasDateFormat);
      const vizteruletHorgaszat: VizteruletHorgaszat = {
        vizteruletId: horgaszNapRequest.vizteruletId,
        megkezdes: horgaszNapRequest.megkezdes,
        befejezes: horgaszNapRequest.befejezes,
        gpsKoordinata: horgaszNapRequest.gpsKoordinata,
        uuid: horgaszNapRequest.uuid,
      };
      horgaszattalToltottNapMap.get(fogasNap).push(vizteruletHorgaszat);
    });
    return horgaszattalToltottNapMap;
  }

  private async addEnaploNyilvantartasToStorage(enaploNyilvantartas: ElektronikusFogasNyilvantartas) {
    await this.localFogasNyilvantartasMutex.runExclusive(async () => {
      const localFogasNyilvantartas = await this.getLocalFogasNyilvantartas();
      localFogasNyilvantartas.enaploNyilvantartas = enaploNyilvantartas;

      //toroljuk ami mar szinkronizalva lett
      const fogasUuidSet = new Set<string>();
      enaploNyilvantartas.fogasok.forEach(fny => fogasUuidSet.add(fny.uuid));
      enaploNyilvantartas.toroltFogasok.forEach(fny => fogasUuidSet.add(fny.fogas.uuid));
      localFogasNyilvantartas.addFogasRequestList = localFogasNyilvantartas.addFogasRequestList
        .filter(afr => !fogasUuidSet.has(afr.uuid));

      //toroljuk a horgasznapokat
      const horgaszNapUuidSet = new Set<string>(enaploNyilvantartas.horgaszattalToltottNapok
        .map(htn => htn.horgaszatList)
        .reduce((acc, horgaszatList) => acc.concat(horgaszatList), [])
        .map(vth => vth.uuid));
      localFogasNyilvantartas.horgaszNapRequestList = localFogasNyilvantartas.horgaszNapRequestList
        .filter(hnr => Utils.hasValue(hnr.uuid))
        .filter(hnr => !horgaszNapUuidSet.has(hnr.uuid));

      await this.saveLocalFogasNyilvantartas(localFogasNyilvantartas);
    });
  }

  private async addHorgaszNapToStorage(horgaszNapRequest: HorgaszNapRequest) {
    await this.localFogasNyilvantartasMutex.runExclusive(async () => {
      const localFogasNyilvantartas = await this.getLocalFogasNyilvantartas();
      const horgaszNapRequestList = localFogasNyilvantartas.horgaszNapRequestList;
      horgaszNapRequestList.filter(hnr => hnr.befejezes === horgaszNapRequest.befejezes)
        .forEach(hnr => hnr.befejezes = Utils.localDateTimeNow());

      //befejezeskor nem tarolunk kulon requestet ha letezik
      const letezoHorgaszNapRequest = horgaszNapRequestList
        .filter(hnr => hnr.fogasiNaploId === horgaszNapRequest.fogasiNaploId)
        .filter(hnr => hnr.vizteruletId === horgaszNapRequest.vizteruletId)
        .find(hnr => hnr.megkezdes === horgaszNapRequest.megkezdes);
      if(letezoHorgaszNapRequest){
        letezoHorgaszNapRequest.befejezes = horgaszNapRequest.befejezes;
      } else {
        horgaszNapRequestList.push(horgaszNapRequest);
      }
      localFogasNyilvantartas.horgaszNapRequestList = horgaszNapRequestList;
      await this.saveLocalFogasNyilvantartas(localFogasNyilvantartas);
    });
  }

  private async addFogasToStorage(request: AddFogasRequest) {
    await this.localFogasNyilvantartasMutex.runExclusive(async () => {
      const localFogasNyilvantartas = await this.getLocalFogasNyilvantartas();
      localFogasNyilvantartas.addFogasRequestList.push(request);
      await this.saveLocalFogasNyilvantartas(localFogasNyilvantartas);
    });
  }

  private async syncHorgaszNapRequests() {
    const localFogasNyilvantartas = await this.getLocalFogasNyilvantartas();
    const localHorgaszNapok = localFogasNyilvantartas.horgaszNapRequestList;
    if(localHorgaszNapok.length > 0) {
      const jobs = localHorgaszNapok.map(request =>
        this.mobilAppControllerService.enaploAddHorgaszNap(request).toPromise()
      );
      await Promise.all(jobs);
    }
    return localHorgaszNapok.length > 0;
  }

  private async syncLocalFogasok() {
    const localFogasNyilvantartas = await this.getLocalFogasNyilvantartas();
    const localAddFogasRequestList = localFogasNyilvantartas.addFogasRequestList;
    if (localAddFogasRequestList.length > 0) {
      const jobs = localAddFogasRequestList.map(fogas =>
        this.mobilAppControllerService.enaploAddFogas(fogas).toPromise()
      );
      await Promise.all(jobs);
    }
    return localAddFogasRequestList.length > 0;
  }

  private nextRefreshedDate() {
    this.cacheService.getCacheItem(this.getCacheId()).then(item => {
      if(item){
        this.refreshedDateSubject.next(item.cachedDate);
      }
    });
  }
}
