import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Base64Content, MobilAppControllerService } from 'api';
import { LocalStoreKey } from 'app/core/local-store-key';
import { SafeHtmlUrlPipe } from 'app/core/safe-html-url.pipe';
import { CacheService } from 'app/services/cache.service';
import { Utils } from '../utils';

@Component({
  selector: 'horgasz-base64-content',
  templateUrl: './base64-content.component.html',
  styleUrls: ['./base64-content.component.scss'],
})
export class Base64ContentComponent implements OnInit, OnChanges {

  @Input() content: Base64Content | undefined;
  @Input() contentId: number | undefined;
  @Input() type: 'image' | 'embed' = 'image';
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() borderRadius: string;

  safeResourceUrl: SafeResourceUrl;

  constructor(
    private safeHtmlUrlPipe: SafeHtmlUrlPipe,
    private mobilAppControllerService: MobilAppControllerService,
    private cacheService: CacheService,
  ) { }

  ngOnInit(): void {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.content && this.content) {
      this.safeResourceUrl = this.safeHtmlUrlPipe.transformBase64Content(this.content);
    }
    if (changes.contentId && Utils.hasValue(this.contentId)) {
      const contentStorageKey = LocalStoreKey.BASE64_IMAGE_PREFIX + String(this.contentId);
      this.cacheService.cache<Base64Content>(this.mobilAppControllerService.contentBase64Download({contentId: this.contentId}), contentStorageKey, true)
        .subscribe({
          next: result => this.safeResourceUrl = this.safeHtmlUrlPipe.transformBase64Content(result),
          error: () => {}
        });
    }
  }
}
