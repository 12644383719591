<ion-content class="moh-bg-light">
  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3">
        <horgasz-aktualis-horgaszat-card (ujVizetrulet)="onHorgaszatMegkezdeseClick()"></horgasz-aktualis-horgaszat-card>
        <ion-card class="inline-calendar-card">
          <mat-calendar
            class="moh-calendar"
            [(selected)]="selectedDate"
            (_userSelection)="dateSelected($event)"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [dateClass]="dateClassVar">
          </mat-calendar>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="horgasznapok-footer">
  <div class="ion-text-center">
    <ion-button *ngIf="!selectedVizterulet" (click)="onHorgaszatMegkezdeseClick()">
      Horgászat megkezdése
    </ion-button>
  </div>
  <horgasz-last-modified-refresher
    (refresh)="onLastModifierRefreshEvent()"
    [lastModifiedType]="LastModifiedType.FOGASINAPLO">
  </horgasz-last-modified-refresher>
</ion-footer>

