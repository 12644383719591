import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { DeviceRegistrationRequest, MobilAppControllerService, UserDevice } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { LocalStoreKey } from 'app/core/local-store-key';

import { Utils } from 'app/core/utils';
import { environment } from 'environments/environment';
import { TimerMutex } from '../core/timer-mutex';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceRegistrationService extends OnDestroyMixin{

  protected readonly environment = environment;

  private timerMutex = new TimerMutex(10);//10seconds

  constructor(
    private authService: AuthService,
    private mobilAppControllerService: MobilAppControllerService,
    private cacheService: CacheService
  ) {
    super();
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      if (auth.isAuthenticated()){
        this.checkDeviceRegistration();
      }
    });
  }

  private checkDeviceRegistration(){
    const authentication = this.authService.getAuthentication();
    if(authentication.isAuthenticated()){
      this.timerMutex.runExclusive(async () => {
        const deviceRegistrationId = LocalStoreKey.DEVICE_REGISTRATION_PREFIX + authentication.felhasznalo.szemelyId;
        const deviceRegistration = await this.cacheService.getCacheItem<UserDevice>(deviceRegistrationId);
        if (!deviceRegistration){
          const deviceId = await Device.getId();
          const deviceInfo = await Device.getInfo();
          const request: DeviceRegistrationRequest = {
            loginId: authentication.felhasznalo.loginId,
            userDevice: {
              id: deviceId.identifier,
              appVersion: environment.version,
              registeredAt: Utils.localDateTimeNow(),
              name: deviceInfo.name,
              manufacturer: deviceInfo.manufacturer,
              model: deviceInfo.model,
              operatingSystem: deviceInfo.operatingSystem,
              osVersion: deviceInfo.osVersion,
              platform: deviceInfo.platform,
            }
          };
          try {
            await this.mobilAppControllerService.deviceRegistration(request).toPromise();
            await this.cacheService.set<UserDevice>(deviceRegistrationId, request.userDevice);
          } catch (err) {}
        }
      });
    }
  }
}
