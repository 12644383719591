import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fogasDate',
  standalone: true,
})
export class FogasDatePipe implements PipeTransform {

  private datePipe = new DatePipe('hu-HU');

  constructor() {}

  transform(idopont: Date | string, type?: 'date' | 'timestamp'): any {
    const valueType = type ?? 'timestamp';
    switch (valueType){
      case 'date': return this.datePipe.transform(idopont, 'yyyy.MM.dd.');
      case 'timestamp': return this.datePipe.transform(idopont, 'yyyy.MM.dd. HH:mm');
    }
  }
}
