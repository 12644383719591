import { Component, OnInit } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { HorgaszvizsgaIgazolasNyomtatasRequest, MobilAppControllerService, MobilAppVizsgaOsszesito } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { Utils } from 'app/core/utils';
import { ToastService } from 'app/services/toast.service';

@Component({
  selector: 'horgasz-horgaszvizsga',
  templateUrl: './horgaszvizsga.page.component.html',
  styleUrls: ['../jegyek/jegyek.page.scss']
})
export class HorgaszvizsgaPage extends OnDestroyMixin implements OnInit {

  vizsgaOsszesito: MobilAppVizsgaOsszesito;
  szemelyId: number;

  constructor(
    private authService: AuthService,
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService
  ) {
    super();
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      this.vizsgaOsszesito = auth.felhasznalo?.vizsgaOsszesito;
      this.szemelyId = auth.felhasznalo?.szemelyId;
    });
  }

  ngOnInit() {
  }

  onIgazolasLetoltes(nyelv: HorgaszvizsgaIgazolasNyomtatasRequest.NyelvEnum): void {
    const request: HorgaszvizsgaIgazolasNyomtatasRequest = { szemelyId: this.szemelyId, nyelv };
    this.mobilAppControllerService.horgaszvizsgaIgazolasNyomtatas(request).toPromise()
      .then(result => {
        const filename = 'horgaszvizsga_igazolas_' + nyelv + '.pdf';
        Utils.openBase64Content(result, filename);
      }).catch(() => {});
  }
}
