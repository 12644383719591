<ion-content class="moh-bg-light">
  <div [ngClass]="!vizsgaOsszesito.horgaszvizsgaIgazolva ? 'vertical-center' : ''">
    <ion-grid>
      <ion-row>
        <ion-col size-md="6" offset-md="3">
          <ng-container *ngIf="vizsgaOsszesito.horgaszvizsgaIgazolva; else tplNoContent">
            <ion-card>
              <ion-card-header>
                <ion-item>
                  <ion-avatar slot="start" class="icon-moh-primary">
                    <ion-icon src='assets/icon/state/check-ok.svg'></ion-icon>
                  </ion-avatar>
                  <ion-label class="ion-text-wrap">
                    <h6>Állami horgászvizsgával vagy ezzel egyenértékű nyilvántartott horgászvizsgával rendelkezel.</h6>
                  </ion-label>
                </ion-item>
              </ion-card-header>
              <ion-card-content class="no-padding-top">
                <ion-list lines="none">
                  <ng-container *ngIf="vizsgaOsszesito.sikeresAllamiVizsga; else tplIgazolasHorgaszvizsga">
                    <ion-item>
                      <span slot="start">Vizsga száma:</span>
                      <ion-label><strong>{{vizsgaOsszesito.sikeresAllamiVizsga.vizsgaSzam}}</strong></ion-label>
                    </ion-item>
                    <ion-item>
                      <span slot="start">Vizsga időpont:</span>
                      <ion-label><strong>{{vizsgaOsszesito.sikeresAllamiVizsga.idopont | date: 'short'}}</strong></ion-label>
                    </ion-item>
                  </ng-container>
                  <ng-template #tplIgazolasHorgaszvizsga>
                    <ion-item *ngIf="vizsgaOsszesito.igazoltHorgaszvizsgaSzam">
                      <span slot="start">Vizsga száma:</span>
                      <ion-label><strong>{{vizsgaOsszesito.igazoltHorgaszvizsgaSzam}}</strong></ion-label>
                    </ion-item>
                  </ng-template>
                </ion-list>

                <div class="btn-horgaszvizsga-igazolas">
                  <ion-button fill="outline" class="btn" (click)="onIgazolasLetoltes('HU')">
                    <ion-icon slot="start" name="eye-outline"></ion-icon>
                    Magyar nyelvű igazolás
                  </ion-button>
                  <ion-button fill="outline" class="btn" (click)="onIgazolasLetoltes('EN')">
                    <ion-icon slot="start" name="eye-outline"></ion-icon>
                    Certificate in English
                  </ion-button>
                </div>
              </ion-card-content>
            </ion-card>
          </ng-container>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>

<ng-template #tplNoContent>
  <ion-card class="no-bg ion-text-center">
    <img alt="Figyelem" src="assets/icon/warning.svg" class="moh-warning"/>
    <ion-card-header>
      <ion-card-title>Még nem rendelkezel állami horgászvizsgával.</ion-card-title>
    </ion-card-header>
  </ion-card>
</ng-template>
