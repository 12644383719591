import { Environment } from './model';

export const environment: Environment = {
  release: 'DEV',
  environmentDisplayString: 'DEV',
  production: false,
  hostUrl: 'https://mohosz-dev.plensys.cloud',
  serverUrl: '',
  version: '0',
  versionCode: 0,
  buildTimeString: '0',
};
