import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { AuthRestControllerService, JogosultsagControllerService, MegbizoKivalasztasRequest, MobilAppControllerService, MobilAppFelhasznalo, SssUser, } from 'api';
import { AuthenticationState } from 'app/auth/authentication-state';
import { UserSession } from 'app/auth/user-session';
import { LocalStoreKey } from 'app/core/local-store-key';
import { TimerMutex } from 'app/core/timer-mutex';
import { routesConfig } from 'config/routesConfig';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Utils } from '../core/utils';
import { Authentication } from './authentication';

type ExtSssUser = SssUser & UserSession;

@Injectable({
  providedIn: 'root',
})
export class AuthService extends OnDestroyMixin {

  authentication: Observable<Authentication>;
  private authenticationSubject: BehaviorSubject<Authentication>;
  private userSession?: UserSession;

  private serverRefreshTimerMutex = new TimerMutex(3);

  constructor(
    private router: Router,
    private authRestControllerService: AuthRestControllerService,
    private jogosultsagControllerService: JogosultsagControllerService,
    private mobilAppControllerService: MobilAppControllerService,
    private storage: Storage
  ) {
    super();
    this.authenticationSubject = new BehaviorSubject<Authentication>(new Authentication());
    this.authentication = this.authenticationSubject.asObservable().pipe(filter((auth) =>
      auth?.getState() !== AuthenticationState.INIT
    ));

    this.storage.create().then(() =>
      this.refreshAuthenticationFromServer()
    );
  }

  getAuthentication(): Authentication {
    return this.authenticationSubject.getValue();
  }

  onPlatformResume() {
    if (this.getAuthentication().isAuthenticated()) {
      this.refreshAuthenticationFromServer();
    }
  }

  async refreshAuthenticationFromServer() {
    //elso hivasnal fontos hogy legyen betoltott ertek
    await this.getSessionToken();
    const savedSssUser: SssUser = await this.storage.get(LocalStoreKey.SSSUSER);
    if(savedSssUser) {
      await this.updateAuthentication(savedSssUser);
    } else if(!this.userSession?.sessionToken){
      this.setLogoutAuthentication();
    }
    try {
      const sssUser = await this.authRestControllerService.currentPrincipal().toPromise();
      await this.storage.set(LocalStoreKey.SSSUSER, sssUser);
      await this.updateAuthentication(sssUser);
    } catch (err) {
      const status = Utils.serverErrorStatus(err);
      const isAccessDenied = status === Utils.HTTP_ERROR_CODE_403_ACCESS_DENIED;
      if(isAccessDenied || this.isSessionTokenExpired() || !savedSssUser) {
        console.log('Session token expired');
        this.setLogoutAuthentication();
      }
    }
  }

  async login(username: string, password: string) {
    try {
      const extSssUser: ExtSssUser = await this.authRestControllerService.login(username, password).toPromise();
      await this.setAuthToken(extSssUser);
      const megbizoKivalasztasRequest: MegbizoKivalasztasRequest = { megbizoSzereploId: extSssUser.trusteeId };
      const mkResult = await this.jogosultsagControllerService.megbizoKivalasztas(megbizoKivalasztasRequest).toPromise();
      await this.updateAuthentication(mkResult);
    } catch (err) {
      throw err;
    }
  }

  async logout() {
    try {
      this.setLogoutAuthentication();
      await this.authRestControllerService.logout().toPromise();
    } catch (err) {
      console.error('AuthService.logout: error during logout', err);
    }
  }

  async setAuthToken(userSession: UserSession) {
    this.userSession = {
      sessionToken: userSession.sessionToken,
      sessionExpires: userSession.sessionExpires
    } as UserSession;
    await this.storage.set(LocalStoreKey.USERSESSION, this.userSession);
  }

  fetchAuthToken() {
    return this.userSession.sessionToken;
  }

  async getSessionToken() {
    if (this.userSession) {
      return this.userSession.sessionToken;
    } else {
      this.userSession = await this.storage.get(LocalStoreKey.USERSESSION);
    }
    return this.userSession?.sessionToken;
  }

  isSessionTokenExpired() {
    return this.userSession ? moment().isAfter(moment(this.userSession.sessionExpires)) : true;
  }

  private setLogoutAuthentication() {
    this.storage.remove(LocalStoreKey.USERSESSION).then(() => this.userSession = undefined);

    this.storage.keys().then(keys => {
      const ignorableKeys = [];
      LocalStoreKey.KEEP_KEY_PREFIXES.forEach(prefix => {
        keys.forEach(key => {
          if(key.startsWith(prefix)) {
            ignorableKeys.push(key);
          }
        });
      });
      keys.forEach(key => {
        let removed = false;
        if(!ignorableKeys.includes(key)){
          this.storage.remove(key);
          removed = true;
        }
        console.log('LocalCache key: ' + key + (removed ? ' >>> Removed' : ''));
      });
    });

    this.authenticationSubject.next(this.getAuthentication().logout());
    this.router.navigateByUrl(routesConfig.bejelentkezes, { replaceUrl: true });
  }

  private async updateAuthentication(sssUser: SssUser) {
    const szemelyId = sssUser.trusteeId;
    //ha nem online csatornán van bejelentkezve akkor kidobjuk
    const isOnlineChannel = sssUser.authorityChannel === 'ONLINE';
    if (Utils.hasValue(szemelyId) && isOnlineChannel) {
      let felhasznalo: MobilAppFelhasznalo = await this.storage.get(LocalStoreKey.FELHASZNALO);
      if(felhasznalo) {
        await this.publishFelhasznalo(felhasznalo);
      }
      this.serverRefreshTimerMutex.runExclusive(async () => {
        try {
          felhasznalo = await this.mobilAppControllerService.felhasznaloLekerdezes({ szemelyId }).toPromise();
          await this.storage.set(LocalStoreKey.FELHASZNALO, felhasznalo);
          await this.publishFelhasznalo(felhasznalo);
        } catch (err) {
          const status = Utils.serverErrorStatus(err);
          if(status === Utils.HTTP_ERROR_CODE_403_ACCESS_DENIED || !felhasznalo) {
            this.setLogoutAuthentication();
          }
        }
      });
    } else {
      this.setLogoutAuthentication();
    }
  }

  private async publishFelhasznalo(felhasznalo: MobilAppFelhasznalo) {
    this.authenticationSubject.next(this.getAuthentication().update(felhasznalo));
    if (this.router.url.endsWith('/' + routesConfig.bejelentkezes)) {
      await this.router.navigateByUrl(routesConfig.menu, { replaceUrl: true });
    }
  }
}
