<horgasz-simple-header title="Kapcsolat" [backButton]="true"></horgasz-simple-header>

<ion-content>
  <div class="logo">
    <ion-img src="assets/logo/logo-light-mohosz.svg"></ion-img>
  </div>

  <ion-list lines="none">

    <ion-item>
      <ion-label class="title">KAPCSOLAT</ion-label>
    </ion-item>
    <ion-item style="margin-bottom: 30px">
      <ion-label class="subtitle">Központi Ügyfélszolgálati Iroda</ion-label>
    </ion-item>

    <ion-item>
      <div class="content">
        <ion-icon name="navigate"></ion-icon>
        <a (click)="onAddressClick()">{{mohoszAddress}}</a>
      </div>
    </ion-item>

    <ion-item>
      <div class="content">
        <ion-icon name="mail"></ion-icon>
        <a href="mailto:ugyfelszolgalat@horgaszjegy.hu">ugyfelszolgalat@horgaszjegy.hu</a>
      </div>
    </ion-item>

    <ion-item>
      <div class="content">
        <ion-icon name="globe"></ion-icon>
        <a href="https://tudastar.horinfo.hu">https://tudastar.horinfo.hu</a>
      </div>
    </ion-item>

    <ion-item>
      <div class="content">
        <ion-icon name="call"></ion-icon>
        <a href="tel:+3614450555">+36 1 445 05 55</a>
        &nbsp; &nbsp;(H-V 8-16:30)
      </div>
    </ion-item>

  </ion-list>

  <div class="bg-login-bottom">
    <img alt="" src="assets/bg-login.svg">
  </div>

  <horgasz-version></horgasz-version>
</ion-content>
