/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddFogasRequest } from '../model/addFogasRequest';
import { AddFogasResponse } from '../model/addFogasResponse';
import { Base64Content } from '../model/base64Content';
import { ContentRequest } from '../model/contentRequest';
import { DeviceRegistrationRequest } from '../model/deviceRegistrationRequest';
import { ElektronikusFogasNyilvantartas } from '../model/elektronikusFogasNyilvantartas';
import { EnaploNyilatkozatRequest } from '../model/enaploNyilatkozatRequest';
import { Flog } from '../model/flog';
import { FlogLetrehozasRequest } from '../model/flogLetrehozasRequest';
import { FlogModositasRequest } from '../model/flogModositasRequest';
import { FlogRequest } from '../model/flogRequest';
import { FogasNyilvantartasRequest } from '../model/fogasNyilvantartasRequest';
import { FogasokTorzsadat } from '../model/fogasokTorzsadat';
import { GPSKoordinata } from '../model/gPSKoordinata';
import { Halfaj } from '../model/halfaj';
import { HorgaszNapRequest } from '../model/horgaszNapRequest';
import { HorgasztatasSzolgaltatasBundle } from '../model/horgasztatasSzolgaltatasBundle';
import { HorgasztatasSzolgaltatasTorzsadat } from '../model/horgasztatasSzolgaltatasTorzsadat';
import { HorgaszturizmusMapPoi } from '../model/horgaszturizmusMapPoi';
import { HorgaszturizmusPoiItem } from '../model/horgaszturizmusPoiItem';
import { HorgaszturizmusVedettMapPoi } from '../model/horgaszturizmusVedettMapPoi';
import { HorgaszvizsgaIgazolasNyomtatasRequest } from '../model/horgaszvizsgaIgazolasNyomtatasRequest';
import { KedvencHorgaszhely } from '../model/kedvencHorgaszhely';
import { KedvencHorgaszhelyFilter } from '../model/kedvencHorgaszhelyFilter';
import { KedvencHorgaszhelyLetrehozasRequest } from '../model/kedvencHorgaszhelyLetrehozasRequest';
import { KedvencHorgaszhelyModositasRequest } from '../model/kedvencHorgaszhelyModositasRequest';
import { KedvencHorgaszhelyRequest } from '../model/kedvencHorgaszhelyRequest';
import { KedvencHorgaszhelyTorzsadat } from '../model/kedvencHorgaszhelyTorzsadat';
import { MobilAppFelhasznalo } from '../model/mobilAppFelhasznalo';
import { MobilappMobilappfogaskepuploadBody } from '../model/mobilappMobilappfogaskepuploadBody';
import { MobilappMobilappkedvenchorgaszhelykepfeltoltesBody } from '../model/mobilappMobilappkedvenchorgaszhelykepfeltoltesBody';
import { PostafiokListazasResponse } from '../model/postafiokListazasResponse';
import { SzemelyRequest } from '../model/szemelyRequest';
import { SzereploRequest } from '../model/szereploRequest';
import { SzolgaltatasRequest } from '../model/szolgaltatasRequest';
import { SzolgaltatoNevjegy } from '../model/szolgaltatoNevjegy';
import { TeruletiJegyTermekRequest } from '../model/teruletiJegyTermekRequest';
import { TorzsadatBundle } from '../model/torzsadatBundle';
import { UploadFileResponse } from '../model/uploadFileResponse';
import { UzenetKezbesitesRequest } from '../model/uzenetKezbesitesRequest';
import { VersenyzoiEngedelyRequest } from '../model/versenyzoiEngedelyRequest';
import { VizteruletTorzsadat } from '../model/vizteruletTorzsadat';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MobilAppControllerService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contentBase64Download(body: ContentRequest, observe?: 'body', reportProgress?: boolean): Observable<Base64Content>;
    public contentBase64Download(body: ContentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Base64Content>>;
    public contentBase64Download(body: ContentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Base64Content>>;
    public contentBase64Download(body: ContentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling contentBase64Download.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Base64Content>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-content-base64download`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceRegistration(body: DeviceRegistrationRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deviceRegistration(body: DeviceRegistrationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deviceRegistration(body: DeviceRegistrationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deviceRegistration(body: DeviceRegistrationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deviceRegistration.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-device-registration`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enaploAddFogas(body: AddFogasRequest, observe?: 'body', reportProgress?: boolean): Observable<AddFogasResponse>;
    public enaploAddFogas(body: AddFogasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AddFogasResponse>>;
    public enaploAddFogas(body: AddFogasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AddFogasResponse>>;
    public enaploAddFogas(body: AddFogasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling enaploAddFogas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AddFogasResponse>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-enaplo-addfogas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enaploAddHorgaszNap(body: HorgaszNapRequest, observe?: 'body', reportProgress?: boolean): Observable<ElektronikusFogasNyilvantartas>;
    public enaploAddHorgaszNap(body: HorgaszNapRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElektronikusFogasNyilvantartas>>;
    public enaploAddHorgaszNap(body: HorgaszNapRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElektronikusFogasNyilvantartas>>;
    public enaploAddHorgaszNap(body: HorgaszNapRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling enaploAddHorgaszNap.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ElektronikusFogasNyilvantartas>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-enaplo-addhorgasznap`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enaploForecastAddFogas(body: AddFogasRequest, observe?: 'body', reportProgress?: boolean): Observable<AddFogasResponse>;
    public enaploForecastAddFogas(body: AddFogasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AddFogasResponse>>;
    public enaploForecastAddFogas(body: AddFogasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AddFogasResponse>>;
    public enaploForecastAddFogas(body: AddFogasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling enaploForecastAddFogas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AddFogasResponse>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-enaplo-forecastaddfogas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enaploKitoltesiUtmutato(observe?: 'body', reportProgress?: boolean): Observable<Base64Content>;
    public enaploKitoltesiUtmutato(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Base64Content>>;
    public enaploKitoltesiUtmutato(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Base64Content>>;
    public enaploKitoltesiUtmutato(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Base64Content>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-enaplo-utmutato`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enaploLekerdezes(body: FogasNyilvantartasRequest, observe?: 'body', reportProgress?: boolean): Observable<ElektronikusFogasNyilvantartas>;
    public enaploLekerdezes(body: FogasNyilvantartasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ElektronikusFogasNyilvantartas>>;
    public enaploLekerdezes(body: FogasNyilvantartasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ElektronikusFogasNyilvantartas>>;
    public enaploLekerdezes(body: FogasNyilvantartasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling enaploLekerdezes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ElektronikusFogasNyilvantartas>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-enaplo-lekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enaploNyilatkozat(body: EnaploNyilatkozatRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public enaploNyilatkozat(body: EnaploNyilatkozatRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public enaploNyilatkozat(body: EnaploNyilatkozatRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public enaploNyilatkozat(body: EnaploNyilatkozatRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling enaploNyilatkozat.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-enaplo-nyilatkozat`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public felhasznaloLekerdezes(body: SzemelyRequest, observe?: 'body', reportProgress?: boolean): Observable<MobilAppFelhasznalo>;
    public felhasznaloLekerdezes(body: SzemelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MobilAppFelhasznalo>>;
    public felhasznaloLekerdezes(body: SzemelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MobilAppFelhasznalo>>;
    public felhasznaloLekerdezes(body: SzemelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling felhasznaloLekerdezes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MobilAppFelhasznalo>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-felhasznalo-lekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchAllTorzsadat(observe?: 'body', reportProgress?: boolean): Observable<TorzsadatBundle>;
    public fetchAllTorzsadat(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TorzsadatBundle>>;
    public fetchAllTorzsadat(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TorzsadatBundle>>;
    public fetchAllTorzsadat(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TorzsadatBundle>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-torzsadat`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchById(body: number, observe?: 'body', reportProgress?: boolean): Observable<HorgaszturizmusPoiItem>;
    public fetchById(body: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgaszturizmusPoiItem>>;
    public fetchById(body: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgaszturizmusPoiItem>>;
    public fetchById(body: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fetchById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgaszturizmusPoiItem>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-felfedezes-poi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllPois(observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszturizmusMapPoi>>;
    public findAllPois(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszturizmusMapPoi>>>;
    public findAllPois(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszturizmusMapPoi>>>;
    public findAllPois(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<HorgaszturizmusMapPoi>>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-felfedezes-allpois`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllVedettPois(observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszturizmusVedettMapPoi>>;
    public findAllVedettPois(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszturizmusVedettMapPoi>>>;
    public findAllVedettPois(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszturizmusVedettMapPoi>>>;
    public findAllVedettPois(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<HorgaszturizmusVedettMapPoi>>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-felfedezes-allvedettmappois`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findNearbyMapPois(body: GPSKoordinata, observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszturizmusMapPoi>>;
    public findNearbyMapPois(body: GPSKoordinata, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszturizmusMapPoi>>>;
    public findNearbyMapPois(body: GPSKoordinata, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszturizmusMapPoi>>>;
    public findNearbyMapPois(body: GPSKoordinata, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findNearbyMapPois.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<HorgaszturizmusMapPoi>>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-felfedezes-nearbymappois`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findNearbyPoiItems(body: GPSKoordinata, observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszturizmusPoiItem>>;
    public findNearbyPoiItems(body: GPSKoordinata, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszturizmusPoiItem>>>;
    public findNearbyPoiItems(body: GPSKoordinata, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszturizmusPoiItem>>>;
    public findNearbyPoiItems(body: GPSKoordinata, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findNearbyPoiItems.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<HorgaszturizmusPoiItem>>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-felfedezes-nearbypoiitems`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findVedettNearbyMapPois(body: GPSKoordinata, observe?: 'body', reportProgress?: boolean): Observable<Array<HorgaszturizmusVedettMapPoi>>;
    public findVedettNearbyMapPois(body: GPSKoordinata, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<HorgaszturizmusVedettMapPoi>>>;
    public findVedettNearbyMapPois(body: GPSKoordinata, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<HorgaszturizmusVedettMapPoi>>>;
    public findVedettNearbyMapPois(body: GPSKoordinata, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling findVedettNearbyMapPois.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<HorgaszturizmusVedettMapPoi>>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-felfedezes-vedettnearbymappois`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasKepLetoltes(body: FlogRequest, observe?: 'body', reportProgress?: boolean): Observable<Base64Content>;
    public fogasKepLetoltes(body: FlogRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Base64Content>>;
    public fogasKepLetoltes(body: FlogRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Base64Content>>;
    public fogasKepLetoltes(body: FlogRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasKepLetoltes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Base64Content>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-fogaskep-download`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasLetrehozas(body: FlogLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<Flog>;
    public fogasLetrehozas(body: FlogLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Flog>>;
    public fogasLetrehozas(body: FlogLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Flog>>;
    public fogasLetrehozas(body: FlogLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasLetrehozas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Flog>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-fogas-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasListazas(observe?: 'body', reportProgress?: boolean): Observable<Array<Flog>>;
    public fogasListazas(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Flog>>>;
    public fogasListazas(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Flog>>>;
    public fogasListazas(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Flog>>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-fogas-listazas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasModositas(body: FlogModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<Flog>;
    public fogasModositas(body: FlogModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Flog>>;
    public fogasModositas(body: FlogModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Flog>>;
    public fogasModositas(body: FlogModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasModositas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Flog>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-fogas-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogasTorles(body: FlogRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public fogasTorles(body: FlogRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public fogasTorles(body: FlogRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public fogasTorles(body: FlogRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling fogasTorles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-fogas-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fogaskepFeltoltes(body?: MobilappMobilappfogaskepuploadBody, observe?: 'body', reportProgress?: boolean): Observable<UploadFileResponse>;
    public fogaskepFeltoltes(body?: MobilappMobilappfogaskepuploadBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadFileResponse>>;
    public fogaskepFeltoltes(body?: MobilappMobilappfogaskepuploadBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadFileResponse>>;
    public fogaskepFeltoltes(body?: MobilappMobilappfogaskepuploadBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UploadFileResponse>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-fogaskep-upload`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public halfajList(observe?: 'body', reportProgress?: boolean): Observable<Array<Halfaj>>;
    public halfajList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Halfaj>>>;
    public halfajList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Halfaj>>>;
    public halfajList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Halfaj>>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-halfajok`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszrendNyomtatas(body: TeruletiJegyTermekRequest, observe?: 'body', reportProgress?: boolean): Observable<Base64Content>;
    public horgaszrendNyomtatas(body: TeruletiJegyTermekRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Base64Content>>;
    public horgaszrendNyomtatas(body: TeruletiJegyTermekRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Base64Content>>;
    public horgaszrendNyomtatas(body: TeruletiJegyTermekRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszrendNyomtatas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Base64Content>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-horgaszrend-nyomtatas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgasztatasIdLekerdezes(body: number, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public horgasztatasIdLekerdezes(body: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public horgasztatasIdLekerdezes(body: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public horgasztatasIdLekerdezes(body: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgasztatasIdLekerdezes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-horgasztatasid-lekerdezes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgasztatasReszletek(body: SzolgaltatasRequest, observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasBundle>;
    public horgasztatasReszletek(body: SzolgaltatasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasBundle>>;
    public horgasztatasReszletek(body: SzolgaltatasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasBundle>>;
    public horgasztatasReszletek(body: SzolgaltatasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgasztatasReszletek.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<HorgasztatasSzolgaltatasBundle>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-horgasztatas-reszletek`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgasztatasTorzsadat(observe?: 'body', reportProgress?: boolean): Observable<HorgasztatasSzolgaltatasTorzsadat>;
    public horgasztatasTorzsadat(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HorgasztatasSzolgaltatasTorzsadat>>;
    public horgasztatasTorzsadat(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HorgasztatasSzolgaltatasTorzsadat>>;
    public horgasztatasTorzsadat(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<HorgasztatasSzolgaltatasTorzsadat>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-horgasztatas-torzsadat`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public horgaszvizsgaIgazolasNyomtatas(body: HorgaszvizsgaIgazolasNyomtatasRequest, observe?: 'body', reportProgress?: boolean): Observable<Base64Content>;
    public horgaszvizsgaIgazolasNyomtatas(body: HorgaszvizsgaIgazolasNyomtatasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Base64Content>>;
    public horgaszvizsgaIgazolasNyomtatas(body: HorgaszvizsgaIgazolasNyomtatasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Base64Content>>;
    public horgaszvizsgaIgazolasNyomtatas(body: HorgaszvizsgaIgazolasNyomtatasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling horgaszvizsgaIgazolasNyomtatas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Base64Content>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-horgaszvizsgaigazolas-nyomtatas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kedvencHorgaszhelyKepLetoltes(body: KedvencHorgaszhelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Base64Content>;
    public kedvencHorgaszhelyKepLetoltes(body: KedvencHorgaszhelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Base64Content>>;
    public kedvencHorgaszhelyKepLetoltes(body: KedvencHorgaszhelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Base64Content>>;
    public kedvencHorgaszhelyKepLetoltes(body: KedvencHorgaszhelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kedvencHorgaszhelyKepLetoltes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Base64Content>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-kedvenchorgaszhely-kepletoltes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kedvencHorgaszhelyKepUpload(body?: MobilappMobilappkedvenchorgaszhelykepfeltoltesBody, observe?: 'body', reportProgress?: boolean): Observable<UploadFileResponse>;
    public kedvencHorgaszhelyKepUpload(body?: MobilappMobilappkedvenchorgaszhelykepfeltoltesBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadFileResponse>>;
    public kedvencHorgaszhelyKepUpload(body?: MobilappMobilappkedvenchorgaszhelykepfeltoltesBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadFileResponse>>;
    public kedvencHorgaszhelyKepUpload(body?: MobilappMobilappkedvenchorgaszhelykepfeltoltesBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UploadFileResponse>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-kedvenchorgaszhely-kepfeltoltes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kedvencHorgaszhelyLetrehozas(body: KedvencHorgaszhelyLetrehozasRequest, observe?: 'body', reportProgress?: boolean): Observable<KedvencHorgaszhely>;
    public kedvencHorgaszhelyLetrehozas(body: KedvencHorgaszhelyLetrehozasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KedvencHorgaszhely>>;
    public kedvencHorgaszhelyLetrehozas(body: KedvencHorgaszhelyLetrehozasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KedvencHorgaszhely>>;
    public kedvencHorgaszhelyLetrehozas(body: KedvencHorgaszhelyLetrehozasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kedvencHorgaszhelyLetrehozas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KedvencHorgaszhely>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-kedvenchorgaszhely-letrehozas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kedvencHorgaszhelyListazas(body: KedvencHorgaszhelyFilter, observe?: 'body', reportProgress?: boolean): Observable<Array<KedvencHorgaszhely>>;
    public kedvencHorgaszhelyListazas(body: KedvencHorgaszhelyFilter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<KedvencHorgaszhely>>>;
    public kedvencHorgaszhelyListazas(body: KedvencHorgaszhelyFilter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<KedvencHorgaszhely>>>;
    public kedvencHorgaszhelyListazas(body: KedvencHorgaszhelyFilter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kedvencHorgaszhelyListazas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<KedvencHorgaszhely>>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-kedvenchorgaszhely-listazas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kedvencHorgaszhelyModositas(body: KedvencHorgaszhelyModositasRequest, observe?: 'body', reportProgress?: boolean): Observable<KedvencHorgaszhely>;
    public kedvencHorgaszhelyModositas(body: KedvencHorgaszhelyModositasRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KedvencHorgaszhely>>;
    public kedvencHorgaszhelyModositas(body: KedvencHorgaszhelyModositasRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KedvencHorgaszhely>>;
    public kedvencHorgaszhelyModositas(body: KedvencHorgaszhelyModositasRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kedvencHorgaszhelyModositas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<KedvencHorgaszhely>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-kedvenchorgaszhely-modositas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kedvencHorgaszhelyTorles(body: KedvencHorgaszhelyRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public kedvencHorgaszhelyTorles(body: KedvencHorgaszhelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public kedvencHorgaszhelyTorles(body: KedvencHorgaszhelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public kedvencHorgaszhelyTorles(body: KedvencHorgaszhelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kedvencHorgaszhelyTorles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-kedvenchorgaszhely-torles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kedvencHorgaszhelyTorzsadat(observe?: 'body', reportProgress?: boolean): Observable<KedvencHorgaszhelyTorzsadat>;
    public kedvencHorgaszhelyTorzsadat(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KedvencHorgaszhelyTorzsadat>>;
    public kedvencHorgaszhelyTorzsadat(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KedvencHorgaszhelyTorzsadat>>;
    public kedvencHorgaszhelyTorzsadat(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<KedvencHorgaszhelyTorzsadat>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-kedvenchorgaszhely-torzsadat`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public kezbesites(body: UzenetKezbesitesRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public kezbesites(body: UzenetKezbesitesRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public kezbesites(body: UzenetKezbesitesRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public kezbesites(body: UzenetKezbesitesRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling kezbesites.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/mohosz/mobilapp/postafiok-kezbesites`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listazas(observe?: 'body', reportProgress?: boolean): Observable<PostafiokListazasResponse>;
    public listazas(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PostafiokListazasResponse>>;
    public listazas(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PostafiokListazasResponse>>;
    public listazas(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PostafiokListazasResponse>('post',`${this.basePath}/api/mohosz/mobilapp/postafiok-listazas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nemKezbesitettSzamossag(observe?: 'body', reportProgress?: boolean): Observable<number>;
    public nemKezbesitettSzamossag(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public nemKezbesitettSzamossag(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public nemKezbesitettSzamossag(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('post',`${this.basePath}/api/mohosz/mobilapp/postafiok-nemkezbesitett-szamossag`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public szolgaltatoNevjegy(body: SzereploRequest, observe?: 'body', reportProgress?: boolean): Observable<SzolgaltatoNevjegy>;
    public szolgaltatoNevjegy(body: SzereploRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SzolgaltatoNevjegy>>;
    public szolgaltatoNevjegy(body: SzereploRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SzolgaltatoNevjegy>>;
    public szolgaltatoNevjegy(body: SzereploRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling szolgaltatoNevjegy.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<SzolgaltatoNevjegy>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-szolgaltato-nevjegy`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public torzsadat(observe?: 'body', reportProgress?: boolean): Observable<FogasokTorzsadat>;
    public torzsadat(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FogasokTorzsadat>>;
    public torzsadat(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FogasokTorzsadat>>;
    public torzsadat(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FogasokTorzsadat>('get',`${this.basePath}/api/mohosz/mobilapp/mobilapp-fogas-torzsadat`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public versenyzoiEngedelyNyomtatas(body: VersenyzoiEngedelyRequest, observe?: 'body', reportProgress?: boolean): Observable<Base64Content>;
    public versenyzoiEngedelyNyomtatas(body: VersenyzoiEngedelyRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Base64Content>>;
    public versenyzoiEngedelyNyomtatas(body: VersenyzoiEngedelyRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Base64Content>>;
    public versenyzoiEngedelyNyomtatas(body: VersenyzoiEngedelyRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling versenyzoiEngedelyNyomtatas.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Base64Content>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-versenyzoiengedely-nyomtatas`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public vizteruletList(observe?: 'body', reportProgress?: boolean): Observable<Array<VizteruletTorzsadat>>;
    public vizteruletList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VizteruletTorzsadat>>>;
    public vizteruletList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VizteruletTorzsadat>>>;
    public vizteruletList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VizteruletTorzsadat>>('post',`${this.basePath}/api/mohosz/mobilapp/mobilapp-vizteruletek`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
