import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

import { IonicModule } from '@ionic/angular';
import { FogasDatePipe } from 'app/altalanos/pipes/fogas-date.pipe';
import { FogasSulyPipe } from 'app/altalanos/pipes/fogas-suly.pipe';
import { VizteruletPipe } from 'app/altalanos/pipes/vizterulet.pipe';
import { CoreModule } from 'app/core/core.module';
import { CustomDateAdapter } from 'app/core/custom-date-adapter.service';
import { FogasCardComponent } from 'app/fogasi-naplo/fogasok/fogas-card/fogas-card.component';
import { FogasokPageRoutingModule } from 'app/fogasi-naplo/fogasok/fogasok-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { FogasokPage } from './fogasok.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CoreModule,
    SharedModule,
    FogasokPageRoutingModule,
    FogasDatePipe,
    FogasSulyPipe,
    VizteruletPipe,
  ],
  declarations: [
    FogasokPage,
    FogasCardComponent,
  ],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter}
  ]
})
export class FogasokPageModule {
}
