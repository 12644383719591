import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { MobilAppControllerService, TeruletiJegy, TeruletiJegyTermekRequest } from 'api';
import { ErvenyessegDatumPipe } from 'app/altalanos/pipes/ervenyesseg-datum-pipe';
import { Utils } from 'app/core/utils';
import { ToastService } from 'app/services/toast.service';


@Component({
  selector: 'horgasz-teruleti-jegy-card',
  templateUrl: './teruleti-jegy-card.component.html',
  styleUrls: ['../jegyek.page.scss', './teruleti-jegy-card.scss'],
})
export class TeruletiJegyCardComponent extends OnDestroyMixin implements OnInit {

  @Input() vizteruletIds: Array<number>;
  @Input() teruletiJegy: TeruletiJegy;
  @Output() kiegeszitoVasarlas = new EventEmitter();

  ervenyessegDatumPipe = new ErvenyessegDatumPipe();

  constructor(
    private mobilAppControllerService: MobilAppControllerService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
  }

  onHorgaszrendLetoltes() {
    const teruletiJegyTermekId = this.teruletiJegy.teruletiJegyTermekId;
    const request: TeruletiJegyTermekRequest = { teruletiJegyTermekId };
    this.mobilAppControllerService.horgaszrendNyomtatas(request).toPromise()
      .then(result => {
        const filename = 'horgaszrend_' + teruletiJegyTermekId + '.pdf';
        Utils.openBase64Content(result, filename);
      }).catch(() => {});
  }
}
