import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ModalController } from '@ionic/angular';
import { MaskitoOptions } from '@maskito/core';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';

import { AbstractFogas, AddFogasRequest } from 'api';
import { SelectableItem } from 'app/core/apienum/selectable-item';
import { nameofFactory, Utils } from 'app/core/utils';
import { FogasRogzitesModalData } from 'app/fogasi-naplo/fogasok/rogzites-modal/fogas-rogzites-modal-data';
import { FogasRogzitesStep } from 'app/fogasi-naplo/fogasok/rogzites-modal/fogas-rogzites-step';
import { FogasValidacioResponse } from 'app/fogasi-naplo/fogasok/rogzites-modal/fogas-validacio-response';
import { FogasValidacioResult } from 'app/fogasi-naplo/fogasok/rogzites-modal/fogas-validacio-result';
import { FogasiNaploService } from 'app/services/fogasi-naplo.service';
import { LocationService } from 'app/services/location.service';
import { ToastService } from 'app/services/toast.service';
import { v4 as uuidv4 } from 'uuid';

const AFR = nameofFactory<AddFogasRequest>();

@Component({
  selector: 'horgasz-rogzites-modal',
  templateUrl: './fogas-rogzites-modal.page.html',
  styleUrls: ['./fogas-rogzites-modal.page.scss', '../../rogzites-modal.scss'],
})
export class FogasRogzitesModalPage implements OnInit {

  AFR = AFR;
  FogasRogzitesStep = FogasRogzitesStep;
  FogasValidacioResult = FogasValidacioResult;

  maskitoOptions: MaskitoOptions = maskitoNumberOptionsGenerator({
    decimalZeroPadding: true,
    precision: 1,
    decimalSeparator: ',',
    min: 0,
    max: 200,
  });

  fogasRogzitesStep = FogasRogzitesStep.SZERKESZTES;

  data: FogasRogzitesModalData;
  fogas: AbstractFogas;

  form: FormGroup;
  halfajok: Array<SelectableItem> = [];

  forecastFogasRequest: AddFogasRequest | undefined;
  fogasValidacioResponse: FogasValidacioResponse | undefined;

  isRogzitesInProgress = false;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private fogasiNaploService: FogasiNaploService,
    private toastService: ToastService,
    private locationService: LocationService,
  ) { }

  ngOnInit() {
    this.locationService.refresh();
    this.form = this.buildForm();
    //halfaj valaszto ertekkeszlet
    const isNemNyilvantartott = this.data.selectedVizterulet.nyilvantartas === 'NEM_NYILVANTARTOTT';
    this.halfajok = this.data.halfajList
      .filter(halfaj => !isNemNyilvantartott || halfaj.honossag === 'IDEGENHONOS')
      .map(halfaj => SelectableItem.of(halfaj.id, halfaj.nev, 0));
  }

  buildForm() {
    const now = new Date();
    return this.formBuilder.group({
      [AFR('uuid')]: [uuidv4(), [Validators.required]],
      [AFR('fogasiNaploId')]: [this.data.fogasiNaploId, {updateOn: 'blur', validators: [Validators.required]}],
      ['idopontDatum']: [now,{ updateOn: 'blur', validators: [Validators.required] }],
      ['idopontOra']: [now.getHours(), { updateOn: 'blur', validators: [Validators.required, Validators.max(23), Validators.min(0)] }],
      ['idopontPerc']: [now.getMinutes(), { updateOn: 'blur', validators: [Validators.required, Validators.max(59), Validators.min(0)] }],
      [AFR('vizteruletId')]: [this.data.selectedVizterulet.id, {updateOn: 'blur', validators: [Validators.required]}],
      [AFR('halfajId')]: [null, {updateOn: 'blur', validators: [Validators.required]}],
      [AFR('suly')]: [null, {updateOn: 'change', validators: [Validators.required], disabled: true}]
    });
  }

  onFogasMegerositesClick() {
    this.form.markAllAsTouched();
    if(this.form.valid){
      this.forecastFogasRequest = this.buildAddFogasRequest();
      this.fogasValidacioResponse = this.data.validate(this.forecastFogasRequest);
      this.fogasRogzitesStep = FogasRogzitesStep.MEGEROSITES;
    }
  }

  onMegerositesVisszaClick() {
    this.fogasRogzitesStep = FogasRogzitesStep.SZERKESZTES;
  }

  dismiss() {
    this.modalController.dismiss( {dismissed: true} );
  }

  onFogasRogzitesClick() {
    this.isRogzitesInProgress = true;
    const request: AddFogasRequest = this.buildAddFogasRequest();
    this.fogasiNaploService.enaploAddFogas(request)
      .then(() => {
        this.toastService.successMessage('Sikeres fogás rögzítés');
        this.dismiss();
      })
      .finally(() => this.isRogzitesInProgress = false);
  }

  private buildAddFogasRequest(): AddFogasRequest {
    const request: AddFogasRequest = this.form.getRawValue();
    const idopontDatum = this.form.get('idopontDatum').value;
    const idopontOra = this.form.get('idopontOra').value;
    const idopontPerc = this.form.get('idopontPerc').value;
    request.idopont = Utils.toServerDateTime(idopontDatum, idopontOra, idopontPerc);
    const sulyStr = this.form.get('suly').value as string;
    request.suly = Number.parseFloat(sulyStr.replace(',', '.'));
    return request;
  }
}
