import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { AltalanosModule } from 'app/altalanos/altalanos.module';
import { NonBreakingHyphenPipe } from 'app/altalanos/pipes/non-breaking-hyphen.pipe';
import { ErrorMessageComponent } from 'app/core/server-error-message/error-message.component';
import { UtmutatoComponent } from 'app/horgasz-engedelyek/nyilatkozatok/utmutato/utmutato.component';

import { NyilatkozatokPageRoutingModule } from './nyilatkozatok-routing.module';

import { NyilatkozatokPage } from './nyilatkozatok.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NyilatkozatokPageRoutingModule,
    AltalanosModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    NonBreakingHyphenPipe
  ],
  declarations: [
    NyilatkozatokPage,
    UtmutatoComponent
  ]
})
export class NyilatkozatokPageModule {}
