/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ElektronikusFogas } from './elektronikusFogas';
import { HorgaszattalToltottNap } from './horgaszattalToltottNap';
import { ToroltElektronikusFogas } from './toroltElektronikusFogas';

export interface ElektronikusFogasNyilvantartas { 
    ervenyessegEv?: number;
    ervenyessegKezdete?: string;
    ervenyessegVege?: string;
    fogasIdSequence?: number;
    fogasiNaploId: string;
    fogasok?: Array<ElektronikusFogas>;
    horgaszattalToltottNapok?: Array<HorgaszattalToltottNap>;
    horgaszattalToltottNapokSzama?: number;
    rogzitesAlatt?: boolean;
    rogzitesIdopont?: Date;
    rogzitett?: boolean;
    statusz?: ElektronikusFogasNyilvantartas.StatuszEnum;
    toroltFogasok?: Array<ToroltElektronikusFogas>;
}
export namespace ElektronikusFogasNyilvantartas {
    export type StatuszEnum = 'ROGZITES_ALATT' | 'ROGZITETT';
    export const StatuszEnum = {
        ROGZITESALATT: 'ROGZITES_ALATT' as StatuszEnum,
        ROGZITETT: 'ROGZITETT' as StatuszEnum
    };
}