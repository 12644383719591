<div style="text-align:center;">
  <img src="assets/mohosz-logo-text.png" alt="" height="70">
</div>

<div style="padding-left: 10px; padding-right: 10px; text-align: justify;">
  <p style="font-weight: bold; text-align: center">Az elektronikus fogási napló (E-napló)<br> vezetésének szabályai</p>
  <p class="bekezdes">
    Az idei év február 1. napjától - a korábbi évektől eltérően a 18 év feletti, magyar horgászegyesületi tagsággal rendelkező horgásznak választási lehetősége van, hogy a horgász fogási naplóját papír alapon kívánja továbbra is vezetni vagy a digitalizáció keretében áttér az elektronikus fogási napló (a továbbiakban: E-napló) vezetésére.
  </p>
  <p class="bekezdes">
    <span [innerHTML]="'Az E-napló vezetését a halgazdálkodás és a halvédelem egyes szabályainak megállapításáról szóló 133/2013. (XII. 29.) VM rendelet (a továbbiakban: Vhr.) 25. § (4) bekezdésében foglaltak teszik lehetővé valamennyi horgász számára. Érdemes megjegyezni, hogy az adatrögzítés tartalmában nincs eltérés a papíralapon és az elektronikusan vezetett naplók között.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span class="kiemelt">Első fontos információ: </span>
    <span [innerHTML]="'Annak a horgásznak, aki 2025. január 1. után már kiváltotta az új horgászévre a papíralapú fogási naplót, ebben az évben már nem adható ki E-napló.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span class="kiemelt">Második fontos információ: </span>
    <span [innerHTML]="'A horgásznak rendelkeznie kell olyan okos eszközzel (pl.: mobiltelefon), melyre letöltötte a MOHOSZ HORGÁSZ applikációt és ismeri a belépéshez szükséges felhasználói nevet és jelszót, ami megegyezik a horgász regisztrációjakor a HORINFO szakrendszerbe megadott vagy az időközben általa módosított adatokkal.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span [innerHTML]="'Az E-napló vezetése egyes pontokon eltér majd a papíralapú napló vezetésétől, de - miként már jeleztük - a jelenlét és a halfogások rögzítése során a Vhr.-ben foglalt rendelkezések betartása itt is érvényes. Legfontosabb eltérés az, hogy az E-napló vezetésekor a HORGÁSZ applikációba belépve az „E-napló” oldalon először a „Horgászat megkezdése” funkciógomb segítségével ki kell választani a vízterületet (a horgász területi jegyein feltüntetett vízterületek a lista elején jelennek majd meg), melynek véglegesítésével a horgászat napja automatikusan bejegyzésre kerül a naptárfelületen, mint horgásznap. Ezzel a funkcióval az adott nap automatikusan megjelölésre kerül (ami a papíralapú fogási napló esetében az adott nap X-szel történő megjelölését jelentette). Ennél a pontnál felhívjuk a figyelmet arra, hogy a papíralapú fogási naplóval ellentétben mindig csak az adott nap jelenik meg, így nem X-szelhetőek be előre a következő napok. Példa: Egy horgász 3 napos horgászatot tervez és ehhez rendelkezik érvényes területi jeggyel. Az applikáció minden horgásznapot lezár 24 órakor, így a következő napon szükség lesz a jogosult horgászat folytatásához ismét a horgásznak bejelölni ugyanazt a vízterületet. Amennyiben a horgász egy másik vízterületen kívánja adott napon folytatni a horgászatot, úgy az „Új vízterület” gombra koppintva kiválasztható az új vízterület. Az applikációban található egy „Befejezés” funkciógomb is, melynek megnyomásával lezárható a horgászat adott napon, az éppen aktuális vízterületen. Erre a napra is tud a horgászat bármikor új horgászatot indítani akár ugyanarra vízterületre, de bármely másikra is.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span class="kiemelt">Harmadik fontos információ: </span>
    <span [innerHTML]="'A horgásznak a kifogott és elvitere szánt hal súlyára (tömegére), méretére vonatkozó adatok pontos berögzítése szükséges, mert annak későbbi javítására, módosítására az alkalmazás a horgász számára már - értelemszerűen - nem biztosít lehetőséget. Fő szabály szerint így mindig az eredetileg bejegyzett fogási adat számít be a Vhr. vagy a helyi horgászrend szerinti naponta és évente kifogható elviteli mennyiségekbe.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span class="kiemelt">Negyedik fontos információ: </span>
    <span [innerHTML]="'Az E-naplóban rögzíthető fogási korlátozások a jogszabályi előírások szerint kerültek paraméterezésre, így felhívjuk minden horgász figyelmét arra, hogy győződjön meg a helyi horgászrend szerinti - esetlegesen eltérő - szabályokról, halelviteli kvótákról, az elvihető halak méret és darabszámáról, mert az E-naplóba berögzíthető, de a helyi horgászrend szabályaival ellentétes adatok nem mentesítik a jogosulatlanul folytatott horgászati tevékenység alól.'| nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span [innerHTML]="'Az E-naplóba történő rögzítés során a halfajok egy legördülő sávból választhatóak ki, amelyek tömegét a papíralapú fogási napló vezetésénél alkalmazott szabályok szerint kell az E-naplóban is vezetni, a telefonon megjelenő számok segítségével beírva. A halak tömegénél az adatot legalább 0,5 kg pontossággal kell beírni úgy, hogy a megtartott hal(ak) esetén legalább 0,5 kg bejegyzett tömegnek mindenképpen szerepelnie kell. Az E-naplóba való fogás rögzítés esetén a rendszer automatikusan biztosítja a tizedesjegy pontosságú felvitelt.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span [innerHTML]="'Az E-napló vezetése esetén is alapvető szabály, hogy a horgász a kifogott és megtartani kívánt darabszám-korlátozás alá eső halat a horogtól való megszabadítás után azonnal köteles bejegyezni. Az ilyen halfajok, továbbá az amur, angolna, pisztrángsügér, szivárványos pisztráng és a tokhalak testtömeg adatait egyenként (darabonként) kell majd rögzíteni.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span [innerHTML]="'A darabszám-korlátozással nem védett (egyéb őshonos és idegenhonos, valamint adatrögzítésre rendelt, beleértve a csalizás céljából felhasznált csalihal mennyiségét is) halfajok mennyiségét - azok össztömegében kifejezve - a horgász a horgászat befejezését követően, de még a vízpart elhagyása előtt köteles a fogási naplóba bejegyezni.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span [innerHTML]="'A bejegyzést naponta többször is el kell végezni, ha a horgász a vízterületet napközben elhagyja, majd visszatér és ugyanazon a napon horgászva ismét darabszám-korlátozással nem védett halfajokat fog ki.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span [innerHTML]="'A fogások rögzítésének jóváhagyása után azok a Vhr.-ben előírt módon (hónap, nap, óra, perc) rögzülni fognak az E-naplóban.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span [innerHTML]="'A horgász a megtartani nem kívánt halat a fogást követően haladéktalanul (vagy az azonnali, illetve halőri bejelentés miatt hamarosan megtörténő fotózást követően) köteles elengedni, melyet a fogási naplóba nem kell bejegyeznie.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">

    <span class="kiemelt">Ötödik fontos információ: </span>
    <span [innerHTML]="'Az applikáció biztosítja a térerő mentes területeken is a fogások rögzítését, illetve a horgászati jogosultság bemutatását. Az applikációnak azonban szüksége van időszakos adatkapcsolatra.  A horgászat csak bejelentkezett applikációval kezdhető meg, ezért felhívjuk minden horgász figyelmét, hogy a horgászat megkezdése előtt még internettel fedett területen minden esetben győződjön meg arról, hogy a horgászathoz szükséges okmányok látszódnak-e az applikációban, továbbá ne jelentkezzen ki az applikációból.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span class="kiemelt">Hatodik fontos információ: </span>
    <span [innerHTML]="'Az applikációba való bejelentkezés kizárólag adatkapcsolat mellett lehetséges! Ám a naplóvezetés akár 10 napig használható adatkapcsolat nélkül. Az alkalmazás bezárása, vagy a készülék újraindítása nem gátolja a működést. Kijelentkezés esetén vagy a 10 napos határidő lejárta követően kizárólag új adatkapcsolati felvétel mellett lehetséges az újbóli belépés.' | nonBreakingHyphen"></span>
  </p>
  <p class="bekezdes">
    <span class="kiemelt">Hetedik fontos információ: </span>
    <span [innerHTML]="'Természetesen az E-naplóval rendelkező horgász is köteles a horgászati okmányok bemutatására az ellenőrzésre jogosult személyek számára. Ezt az okoseszközén a HORGÁSZ applikációba belépve tudja megtenni. Amennyiben eszköze meghibásodik, lemerül vagy nincs a helyszínen, akkor az okmányok bemutatási kötelezettségét nem tudja biztosítani, így jogosulatlan horgászatot követ el.' | nonBreakingHyphen"></span>
  </p>
  <p style="text-align:center">
    Bízunk benne, hogy ez a tájékoztató is hozzájárult a sikeres továbblépéshez!
  </p>
</div>

