import { ElektronikusFogas } from 'api';
import { Utils } from 'app/core/utils';
import { orderBy } from 'lodash';

export class FogasokGroup {
  pkDate: Date;
  fogasok: Array<ElektronikusFogas>;

  static fromDomain(fogas: ElektronikusFogas){
    const fbm = new FogasokGroup();
    fbm.pkDate = FogasokGroup.buildPk(fogas);
    fbm.fogasok = [fogas];
    return fbm;
  }

  static buildPk(fogas: ElektronikusFogas): Date {
    return Utils.momentDate(fogas.idopont);
  }

  static sort(fogasok: Array<ElektronikusFogas>): Array<ElektronikusFogas> {
    return orderBy<ElektronikusFogas>(fogasok, 'idopont', 'desc');
  }

  static buildGroup(fogasList: Array<ElektronikusFogas>): Array<FogasokGroup> {
    const fogasok = FogasokGroup.sort(fogasList);
    const fogasokMap = new Map<Date, FogasokGroup>();
    fogasok.forEach(fogas => {
      const mapKey = FogasokGroup.buildPk(fogas);
      const existingFogas = fogasokMap.get(mapKey);
      if (existingFogas) {
        existingFogas.addFogas(fogas);
      } else {
        fogasokMap.set(mapKey, FogasokGroup.fromDomain(fogas));
      }
    });
    const fogasokGroups = Array.from(fogasokMap.values());
    return orderBy<FogasokGroup>(fogasokGroups, 'pkDate', 'desc');
  }

  addFogas(fogas: ElektronikusFogas){
    this.fogasok.push(fogas);
    this.fogasok = FogasokGroup.sort(this.fogasok);
  }

}
