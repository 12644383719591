<ion-content class="moh-bg-light" *ngIf="felhasznalo">
  <ion-grid>
    <ion-row>
      <ion-col size-md="6" offset-md="3">

        <h5>Állami horgászjegy</h5>

        <ng-container *ngIf="!allamiJegy; else showAllamiJegy">
          <div class="no-content-block ion-text-left">
            <p>Jelenleg nincs érvényes állami jegyed.</p>
            <p>Állami jegyet jelenleg csak személyesen, az értékesítési pontok egyikén tudsz vásárolni.</p>
            <p>Nézd meg, melyik van hozzád legközelebb!</p>

            <div class="ion-text-center">
              <ion-button fill="outline" class="btn" (click)="onErtekesitesiPontokClick()">
                <ion-icon slot="start" name="map-outline"></ion-icon>
                Értékesítési pontok
              </ion-button>
            </div>
          </div>
        </ng-container>

        <ng-template #showAllamiJegy>
          <horgasz-allami-jegy-card [allamiJegy]="allamiJegy"></horgasz-allami-jegy-card>
        </ng-template>


        <h5>Területi jegy(ek)</h5>

        <ng-container *ngIf="felhasznalo.ervenyesTeruletiJegy?.length === 0; else showTeruletiJegyek">
          <div class="no-content-block ion-text-left">
            <p>Jelenleg nincs érvényes területi jegyed.</p>
            <div class="ion-text-center">
              <ion-button class="button-round" (click)="onTeruletiJegyVasarlasClick()">
                <ion-icon slot="start" src="assets/icon/ticket-outline.svg"></ion-icon>
                Területi jegy vásárlása
              </ion-button>
            </div>
          </div>
        </ng-container>

        <ng-template #showTeruletiJegyek>
          <div *ngIf="felhasznalo.ervenyesTeruletiJegy?.length > 0">
            <ng-container *ngFor="let item of felhasznalo.ervenyesTeruletiJegy">
              <horgasz-teruleti-jegy-card
                [vizteruletIds]="vcsVizteruletMap.get(item.vizteruletCsoportId)"
                [teruletiJegy]="item">
              </horgasz-teruleti-jegy-card>
            </ng-container>
          </div>
        </ng-template>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
