import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HorgasznapokPage } from 'app/fogasi-naplo/horgasznapok/horgasznapok-page.component';
import { HorgaszatMegkezdeseModalPageModule } from './horgaszat-megkezdese-modal/horgaszat-megkezdese-modal.module';
import { HorgaszatokAdottNaponModalPageModule } from './horgaszatok-adott-napon-modal/horgaszatok-adott-napon-modal.module';

const routes: Routes = [
  {
    path: '', component: HorgasznapokPage
  },
  {
    path: 'horgaszatok-adott-napon-modal', loadChildren: () => HorgaszatokAdottNaponModalPageModule
  },
  {
    path: 'horgaszat-megkezdese-modal', loadChildren: () => HorgaszatMegkezdeseModalPageModule
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HorgasznapokPageRoutingModule {}
