import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { VizteruletPipe } from 'app/altalanos/pipes/vizterulet.pipe';
import { AuthService } from 'app/auth/auth.service';
import { SelectableItem } from 'app/core/apienum/selectable-item';
import { Utils } from 'app/core/utils';
import { AktualisHorgaszatService } from 'app/fogasi-naplo/aktualis-horgaszat.service';
import { LocationService } from 'app/services/location.service';
import { orderBy } from 'lodash';
import { HorgaszatMegkezdeseModalData } from './horgaszat-megkezdese-modal-data';

@Component({
  selector: 'horgasz-horgaszat-megkezdese-modal',
  templateUrl: './horgaszat-megkezdese-modal.page.html',
  styleUrls: ['../../rogzites-modal.scss'],
  providers: [VizteruletPipe],
})
export class HorgaszatMegkezdeseModalPage implements OnInit {

  data: HorgaszatMegkezdeseModalData;

  vizteruletek: Array<SelectableItem>;
  form: FormGroup;

  constructor(
    private modalController: ModalController,
    private aktualisHorgaszatService: AktualisHorgaszatService,
    private locationService: LocationService,
    private authService: AuthService,
    private vizteruletPipe: VizteruletPipe,
  ) {
    this.form = new FormGroup({
      ['selectedVizteruletId']: new FormControl()
    });
  }

  ngOnInit() {
    this.locationService.refresh();
    const teruletiJegyVtIds = Utils.distinct(this.authService.getAuthentication().felhasznalo.vcsVizteruletIdsList
      .map(vcsVizteruletIds => vcsVizteruletIds.vizteruletIds)
      .reduce((acc, vizteruletIds) => acc.concat(vizteruletIds), []));

    // vizterulet valaszto ertekkeszlet
    this.vizteruletek = this.data.vizteruletList.map(vizterulet => {
      let priority = teruletiJegyVtIds.includes(vizterulet.id) ? 0 : 1;
      priority = vizterulet.nyilvantartas === 'HORINFO' ? priority : 2;
      return SelectableItem.of(vizterulet.id, this.vizteruletPipe.transform(vizterulet.id), priority);
    });
    SelectableItem.sort(this.vizteruletek);
  }

  dismiss() {
    this.modalController.dismiss({ dismissed: true });
  }

  onHorgaszatMegkezdeseClick() {
    const selectedVizteruletId = this.form.controls.selectedVizteruletId.value;
    this.aktualisHorgaszatService.megkezdes(this.data.vizteruletList.find(vt => vt.id === selectedVizteruletId));
    this.dismiss();
  }

}
