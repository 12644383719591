import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Flog } from 'api';
import { routesConfig } from 'config/routesConfig';
import { FogasaimService } from '../fogasaim.service';

@Component({
  selector: 'horgasz-flog-card',
  templateUrl: './flog-card.component.html',
  styleUrls: ['./flog-card.component.scss']
})
export class FlogCardComponent implements OnInit {

  @Input() flog: Flog;
  LathatosagEnum = Flog.LathatosagEnum;
  AvailableCommandsEnum = Flog.AvailableCommandsEnum;

  constructor(
    private fogasaimService: FogasaimService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  onFogasSzerkesztesClick() {
    this.fogasaimService.setKivalasztottFogas(this.flog);
    this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.fogasaim}/${routesConfig.szerkesztes}`);
  }
}
