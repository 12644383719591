import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AltalanosModule } from 'app/altalanos/altalanos.module';
import { NonBreakingHyphenPipe } from 'app/altalanos/pipes/non-breaking-hyphen.pipe';
import { VizteruletPipe } from 'app/altalanos/pipes/vizterulet.pipe';
import { SharedModule } from 'app/shared/shared.module';
import { AllamiJegyCardComponent } from './allami-jegy-card/allami-jegy-card.component';
import { ErtekesitesiPontokPage } from './ertekesitesi-pontok/ertekesitesi-pontok.page';
import { JegyekPageRoutingModule } from './jegyek-routing.module';
import { JegyekPage } from './jegyek.page';
import { TeruletiJegyCardComponent } from './teruleti-jegy-card/teruleti-jegy-card.component';




@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    JegyekPageRoutingModule,
    AltalanosModule,
    SharedModule,
    VizteruletPipe,
    NonBreakingHyphenPipe
  ],
  declarations: [
    JegyekPage,
    AllamiJegyCardComponent,
    TeruletiJegyCardComponent,
    ErtekesitesiPontokPage
  ]
})
export class JegyekPageModule {}
