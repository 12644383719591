import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from 'app/core/utils';
import { environment } from 'environments/environment';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  protected readonly environment = environment;

  constructor(
    private authService: AuthService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(Promise.resolve(this.authService.getSessionToken())).pipe(
      switchMap((sessionToken) => {
          let nextRequest = sessionToken ? request.clone({
            headers: request.headers.set('X-Auth-Token', sessionToken),
          }) : request;
          nextRequest = nextRequest.clone({
            headers: nextRequest.headers.set('horgaszapp', environment.version),
          });
          return next.handle(nextRequest).pipe(
            catchError((response) => {
              if (response instanceof HttpErrorResponse) {
                const status = Utils.serverErrorStatus(response);
                console.error('Server error code: ' + status);
                if (status === Utils.HTTP_ERROR_CODE_403_ACCESS_DENIED) {
                  if (!response.url.endsWith('/currentprincipal')) {
                    this.authService.logout();
                  }
                } else if(this.authService.isSessionTokenExpired()){
                  //offline modban kidobjuk a felhasznalot
                  if(!response.url.endsWith('/logout')){
                    this.authService.logout();
                  }
                }
              }
              return throwError(response);
            })
          );
        }
      )
    );
  }
}
