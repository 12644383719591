import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HorgaszNapRequest, VizteruletTorzsadat } from 'api';
import { LocalStoreKey } from 'app/core/local-store-key';
import { Utils } from 'app/core/utils';
import { AktualisHorgaszatData } from 'app/fogasi-naplo/aktualis-horgaszat-data';
import { FogasiNaploService } from 'app/services/fogasi-naplo.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AktualisHorgaszatService {

  aktualisHorgaszatDataObservable: Observable<AktualisHorgaszatData>;
  private aktualisHorgaszatDataSubject = new BehaviorSubject<AktualisHorgaszatData>(null);
  private aktualisHorgaszatData: AktualisHorgaszatData = new AktualisHorgaszatData();

  constructor(
    private storage: Storage,
    private fogasiNaploService: FogasiNaploService,
  ) {
    this.aktualisHorgaszatDataObservable = this.aktualisHorgaszatDataSubject.asObservable()
      .pipe(filter(value => !!value));
    this.aktualisHorgaszatDataSubject.next(this.aktualisHorgaszatData);
  }

  refresh(){
    if(this.fogasiNaploService.ervenyesFogasiNaplo?.elektronikus){
      this.storage.get(this.aktualisHorgaszatKey())
        .then(storedAktualisHorgaszat  => {
          if (storedAktualisHorgaszat) {
            this.aktualisHorgaszatData.fromJson(JSON.parse(storedAktualisHorgaszat) as AktualisHorgaszatData);
          } else {
            //megkezdi egyik felhasznalo, majd kilep + belep masik felhasznalo
            const fogasiNaploId = this.fogasiNaploService.ervenyesFogasiNaplo.sorszam;
            if(this.aktualisHorgaszatData.fogasiNaploId !== fogasiNaploId){
              this.aktualisHorgaszatData.befejezes();
              this.aktualisHorgaszatDataSubject.next(this.aktualisHorgaszatData);
            }
          }
          this.aktualisHorgaszatData.refresh();
          this.storage.set(this.aktualisHorgaszatKey(), this.aktualisHorgaszatData.toJson());
          this.aktualisHorgaszatDataSubject.next(this.aktualisHorgaszatData);
        });
    }
  }

  megkezdes(vizterulet: VizteruletTorzsadat){
    const fogasiNaploId = this.fogasiNaploService.ervenyesFogasiNaplo.sorszam;
    this.aktualisHorgaszatData.megkezdes(fogasiNaploId, vizterulet);
    this.aktualisHorgaszatDataSubject.next(this.aktualisHorgaszatData);
    this.addHorgaszNap();
    this.storage.set(this.aktualisHorgaszatKey(), this.aktualisHorgaszatData.toJson());
  }

  befejezes(){
    this.addHorgaszNap(Utils.localDateTimeNow());
    this.aktualisHorgaszatData.befejezes();
    this.aktualisHorgaszatDataSubject.next(this.aktualisHorgaszatData);
    this.storage.remove(this.aktualisHorgaszatKey());
  }

  private addHorgaszNap(befejezes?: Date){
    const fogasiNaploSorszam = this.fogasiNaploService.ervenyesFogasiNaplo.sorszam;
    const request: HorgaszNapRequest = {
      fogasiNaploId: fogasiNaploSorszam,
      vizteruletId: this.aktualisHorgaszatData.selectedVizterulet.id,
      megkezdes: this.aktualisHorgaszatData.horgaszatKezdete,
      befejezes: befejezes ?? Utils.momentTimestamp(moment().endOf('day')),
      uuid: uuidv4()
    };
    this.fogasiNaploService.enaploAddHorgaszNap(request);
  }

  private aktualisHorgaszatKey(){
    const fogasiNaploSorszam = this.fogasiNaploService.ervenyesFogasiNaplo.sorszam;
    return LocalStoreKey.AKTUALIS_HORGASZAT_PREFIX + fogasiNaploSorszam;
  }
}
