import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import {
  Base64Content,
  Flog,
  FlogDetails,
  FlogLetrehozasRequest,
  FlogModositasRequest,
  MobilAppControllerService,
  UploadFileResponse
} from 'api';
import { FlogPipe } from 'app/altalanos/pipes/flog.pipe';
import { HorgaszmodszerPipe } from 'app/altalanos/pipes/horgaszmodszer.pipe';
import { AuthService } from 'app/auth/auth.service';
import { ApiEnumHelper } from 'app/core/apienum/api-enum-helper';
import { SelectableItem } from 'app/core/apienum/selectable-item';
import { Utils, nameofFactory } from 'app/core/utils';
import { FileUploaderConfiguration } from 'app/file-uploader/file-uploader-configuration';
import { FileUploaderMimeType } from 'app/file-uploader/file-uploader-mime-type.enum';
import { FileUploaderResult } from 'app/file-uploader/file-uploader-result';
import { FileUploaderComponent } from 'app/file-uploader/file-uploader/file-uploader.component';
import { ToastService } from 'app/services/toast.service';
import { TorzsadatProviderService } from 'app/services/torzsadat-provider.service';
import { routesConfig } from 'config/routesConfig';
import { merge } from 'rxjs';
import { FogasaimService } from '../fogasaim.service';


const REQ = nameofFactory<FlogModositasRequest>();

@Component({
  selector: 'horgasz-fogas-szerkesztese',
  templateUrl: './fogas-szerkesztese.page.html',
  styleUrls: ['./fogas-szerkesztese.page.scss']
})
export class FogasSzerkesztesePage extends OnDestroyMixin {

  @ViewChild('uploader') uploader: FileUploaderComponent;

  title = 'Fogás szerkesztése';
  isLetrehozas: boolean;
  fileUploaderConfig = new FileUploaderConfiguration();
  uploadEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  uploadedFile: UploadFileResponse;
  mentettKepId: number;
  flog: Flog;
  form: FormGroup;
  vizteruletList: Array<SelectableItem> = [];
  halmatrixList: Array<SelectableItem> = [];
  fogasModszerList: Array<SelectableItem> = [];
  lathatosagList: Array<SelectableItem> = [];

  REQ = REQ;

  base64Content: Base64Content | undefined;

  constructor(
    public router: Router,
    private torzsadatProviderService: TorzsadatProviderService,
    private mobilAppControllerService: MobilAppControllerService,
    private fogasaimService: FogasaimService,
    private alertController: AlertController,
    private toastService: ToastService,
    private horgaszmodszerPipe: HorgaszmodszerPipe,
    private flogPipe: FlogPipe
  ) {
    super();

    // vizteruletek
    this.torzsadatProviderService.vizteruletList.pipe(untilComponentDestroyed(this)).subscribe(
      result => this.vizteruletList = result.map(vizterulet =>
        SelectableItem.of(vizterulet.id, vizterulet.nev, 0)));
    this.torzsadatProviderService.halmatrixList.pipe(untilComponentDestroyed(this)).subscribe(
      result => this.halmatrixList = result.map(halMatrix =>
        SelectableItem.of(halMatrix.id, halMatrix.nev, 0)));
    this.fileUploaderConfig.allowedMimeTypes = [FileUploaderMimeType.JPG, FileUploaderMimeType.PNG];
    this.fileUploaderConfig.maxFileSizeInMb = 10;
    this.fileUploaderConfig.uploadPrefix = '/api/mohosz/mobilapp/mobilapp-fogaskep-upload';
    this.fileUploaderConfig.showHint = true;
    this.fileUploaderConfig.enableFilterByMimeType = true;
    this.fileUploaderConfig.feltoltoGomb.szoveg = 'Kép feltöltése';
    this.fileUploaderConfig.externalUploadEvent = this.uploadEvent;
    this.fogasModszerList = ApiEnumHelper.getSelectableItems(FlogDetails.FogasModszerEnum, this.horgaszmodszerPipe);
    this.lathatosagList = [Flog.LathatosagEnum.PUBLIKUS, Flog.LathatosagEnum.PRIVAT, Flog.LathatosagEnum.ISMEROS].map(item =>
      ({ id: item, nev: this.flogPipe.transform(item), priority: 0 }));
  }

  ionViewDidEnter(){
    this.refresh();
  }

  refresh() {
    this.flog = this.fogasaimService.getKivalasztottFogas();
    if(this.flog && Utils.hasValue(this.flog.details.kepId)){
      this.mobilAppControllerService.fogasKepLetoltes({id: this.flog.id}).toPromise()
        .then(result => this.base64Content = result)
        .catch(() => {});
    }
    this.isLetrehozas = !this.flog;
    this.buildForm();
  }

  onFogasMentesClick() {
    if (this.isLetrehozas) {
      const letrehozasRequest: FlogLetrehozasRequest = this.form.getRawValue();
      this.mobilAppControllerService.fogasLetrehozas(letrehozasRequest).toPromise()
        .then(result => {
          if (Utils.hasValue(result.id)) {
            this.toastService.successMessage('Fogás létrehozása sikeresen megtörtént');
            this.fogasaimService.refreshContent();
            this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.fogasaim}`);
          }
        })
        .catch(() => {});

    } else {
      const modositasRequest: FlogModositasRequest = this.form.getRawValue();
      modositasRequest.id = this.flog.id;
      this.mobilAppControllerService.fogasModositas(modositasRequest).toPromise()
        .then(result => {
          if (Utils.hasValue(result.id)) {
            this.toastService.successMessage('Fogás módosítása sikeresen megtörtént');
            this.fogasaimService.refreshContent();
            this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.fogasaim}`);
          }
        })
        .catch(() => {});
    }
  }

  async onFogasTorlesClick() {
    if (this.flog.id) {
      const confirm = await this.alertController.create({
        header: 'Biztos, hogy töröljük?',
        cssClass: 'moh-alert',
        buttons: [
          {
            text: 'Mégsem',
            cssClass: 'alert-button-cancel',
            role: 'cancel',
            handler: () => {

            },
          },
          {
            text: 'Igen',
            cssClass: 'alert-button-confirm',
            role: 'confirm',
            handler: () => {
              this.mobilAppControllerService.fogasTorles({ id: this.flog.id}).toPromise()
                .then(() => {
                  this.toastService.successMessage('Fogás törlése sikeresen megtörtént');
                  this.fogasaimService.resetKivalasztottFogas();
                  this.fogasaimService.refreshContent();
                  this.router.navigateByUrl(`/${routesConfig.tabs}/${routesConfig.fogasaim}`);
                })
                .catch(() => {});
            },
          },
        ],
      });
      await confirm.present();
    }
  }

  async onEditPhotoClick() {
    await this.uploader.onUploadClick();
  }

  onDeletePhotoClick() {
    this.form.get('kepId').setValue(null);
    this.mentettKepId = null;
    this.uploadedFile = null;
  }

  onSuccessUpload(result: FileUploaderResult) {
    this.uploadedFile = result.uploadedFile;
    this.base64Content = this.uploadedFile.content;
    this.form.get('kepId').setValue(result.id);
  }

  onVisszaengedettChange() {
    const visszaengedett = !!this.form.get(REQ('visszaengedett'))?.value;
    this.form.get(REQ('visszaengedett')).setValue(!visszaengedett);
  }

  private buildForm() {
    const date: Date = Utils.fromServerDate(this.flog?.fogasIdopont.toString()) ?? new Date();
    this.form = new FormGroup({
      [REQ('id')]: new FormControl(this.flog?.id),
      [REQ('fogasIdopont')]: new FormControl(this.flog?.fogasIdopont, [Validators.required]),
      [REQ('vizteruletId')]: new FormControl(this.flog?.vizteruletId, [Validators.required]),
      [REQ('halfajId')]: new FormControl(this.flog?.halfajId, [Validators.required]),
      [REQ('suly')]: new FormControl(this.flog?.details.suly),
      [REQ('hossz')]: new FormControl(this.flog?.details.hossz),
      [REQ('kerulet')]: new FormControl(this.flog?.details.kerulet),
      [REQ('fogasModszer')]: new FormControl(this.flog?.details.fogasModszer),
      [REQ('csali')]: new FormControl(this.flog?.details.csali),
      [REQ('farasztasPerc')]: new FormControl(this.flog?.details.farasztasPerc),
      [REQ('etetoanyag')]: new FormControl(this.flog?.details.etetoanyag),
      [REQ('visszaengedett')]: new FormControl(this.flog?.details.visszaengedett ?? false),
      [REQ('lathatosag')]: new FormControl(
        this.flog?.lathatosag === Flog.LathatosagEnum.PUBLIKUSNAKJELOLT ? Flog.LathatosagEnum.PUBLIKUS : this.flog?.lathatosag, [Validators.required]),
      [REQ('kepId')]: new FormControl(this.flog?.details.kepId, [Validators.required]),
      ['fogasDatum']: new FormControl(date, [Validators.required]),
      ['fogasOra']: new FormControl(date?.getHours(), [Validators.required]),
      ['fogasPerc']: new FormControl(date?.getMinutes(), [Validators.required])
    });
    this.updateForgasIdopont();
    merge(
      this.form.get('fogasDatum')?.valueChanges,
      this.form.get('fogasOra')?.valueChanges,
      this.form.get('fogasPerc')?.valueChanges
    ).pipe(untilComponentDestroyed(this)).subscribe(() =>
      this.updateForgasIdopont()
    );
    this.mentettKepId = this.form.get('kepId')?.value;
  }

  private updateForgasIdopont() {
    if (Utils.hasValue(this.form.get('fogasDatum').value) && Utils.hasValue(this.form.get('fogasOra').value) &&
    Utils.hasValue(this.form.get('fogasPerc').value)) {
      this.form.get(REQ('fogasIdopont')).setValue(Utils.toServerDateTime(
        this.form.get('fogasDatum').value, this.form.get('fogasOra').value, this.form.get('fogasPerc').value));
    } else {
      this.form.get(REQ('fogasIdopont')).setValue(null);
    }
  }
}
