import { Component, inject, Inject, LOCALE_ID, NgZone } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { TextZoom } from '@capacitor/text-zoom';
import { Platform } from '@ionic/angular';
import { NgSelectConfig } from '@ng-select/ng-select';
import { LocaleChangeControllerService } from 'api';
import { AktualisHorgaszatService } from 'app/fogasi-naplo/aktualis-horgaszat.service';
import { AuthService } from './auth/auth.service';
import { FogasiNaploService } from './services/fogasi-naplo.service';
import { LocationService } from './services/location.service';
import { TorzsadatProviderService } from './services/torzsadat-provider.service';

@Component({
  selector: 'horgasz-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    @Inject(LOCALE_ID) locale: string,
    private localeChangeControllerService: LocaleChangeControllerService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private ngSelectConfig: NgSelectConfig,
    private platform: Platform,
    private router: Router,
    private zone: NgZone,
    private authService: AuthService,
    private locationService: LocationService,
    private aktualisHorgaszatService: AktualisHorgaszatService,
    private fogasiNaploService: FogasiNaploService,
  ) {
    inject(LocationService);
    inject(FogasiNaploService);
    inject(TorzsadatProviderService);

    this.localeChangeControllerService.changeLanguage(locale.slice(0, 2)).subscribe({
      error: (err) => console.log('AppComponent: changeLanguage error handled', err)
    });

    this.matIconRegistry.addSvgIcon(
      'moh-icon-calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icon/calendar.svg'
      )
    );
    this.setupNgSelectConfig();

    this.platform.ready().then(() => console.log('platform.ready'));
    this.platform.resume.subscribe(() => {
      console.log('App is resumed (Mobile)');
      this.onPlatformResume();
    });

    if (Capacitor.isPluginAvailable('TextZoom')) {
      TextZoom.set({ value: 1 });
    }

    if (Capacitor.isNativePlatform()) {
      if (window.screen.orientation.lock) {
        // It must be checked for lock method,
        // because it is not supported while debugging iOS in simulator or device
        window.screen.orientation.lock('portrait');
      }
    } else {
      document.addEventListener('visibilitychange', () => {
        if (!document.hidden) {
          console.log('Simulating App Resume (Web)');
          this.onPlatformResume();
        }
      });
    }
  }

  private onPlatformResume(): void {
    this.authService.onPlatformResume();
    this.locationService.refresh();
    this.aktualisHorgaszatService.refresh();
    this.fogasiNaploService.refresh();
  }

  private setupNgSelectConfig() {
    this.ngSelectConfig.notFoundText = 'Nincs találat';
    this.ngSelectConfig.addTagText = 'Hozzáadom';
    this.ngSelectConfig.appendTo = 'body';
    this.ngSelectConfig.bindValue = 'id';
    this.ngSelectConfig.bindLabel = 'nev';
  }
}
