import { Component, Input, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { environment } from 'environments/environment';

@Component({
  selector: 'horgasz-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
  imports: [
    IonicModule
  ],
  standalone: true
})
export class VersionComponent implements OnInit {

  @Input() fixedPosition = true;

  protected readonly environment = environment;

  constructor() {
  }

  ngOnInit() {}

}
