export * from './abstractFogas';
export * from './addFogasRequest';
export * from './addFogasResponse';
export * from './allamiJegy';
export * from './allamiJegyArDimenzio';
export * from './base64Content';
export * from './cim';
export * from './contentRequest';
export * from './elektronikusFogas';
export * from './elektronikusFogasNyilvantartas';
export * from './enaploNyilatkozatRequest';
export * from './felhasznaloMeghivasRequest';
export * from './fizetesiKonstrukcio';
export * from './fizetesiKonstrukcioNapidijBefizetes';
export * from './flog';
export * from './flogDetails';
export * from './flogLetrehozasRequest';
export * from './flogModositasRequest';
export * from './flogRequest';
export * from './fogasNyilvantartasRequest';
export * from './fogasiNaplo';
export * from './fogasiRekord';
export * from './fogasokTorzsadat';
export * from './gPSKoordinata';
export * from './halallomany';
export * from './halfaj';
export * from './halmatrix';
export * from './horgaszNapRequest';
export * from './horgaszattalToltottNap';
export * from './horgaszkartyaPreviewData';
export * from './horgaszmodszer';
export * from './horgasztatasFigyelmeztetoUzenet';
export * from './horgasztatasFogas';
export * from './horgasztatasSzolgaltatasBundle';
export * from './horgasztatasSzolgaltatasRegisztracio';
export * from './horgasztatasSzolgaltatasTorzsadat';
export * from './horgasztatasSzolgaltatasVegrehajtottLepes';
export * from './horgaszturizmusMapPoi';
export * from './horgaszturizmusPoiItem';
export * from './horgaszturizmusVedettMapPoi';
export * from './horgaszvizsgaIgazolasNyomtatasRequest';
export * from './jogosultsag';
export * from './jogosultsagTorlesRequest';
export * from './kedvencHorgaszhely';
export * from './kedvencHorgaszhelyDetails';
export * from './kedvencHorgaszhelyFilter';
export * from './kedvencHorgaszhelyLetrehozasRequest';
export * from './kedvencHorgaszhelyModositasRequest';
export * from './kedvencHorgaszhelyRequest';
export * from './kedvencHorgaszhelyTorzsadat';
export * from './megbizoDetails';
export * from './megbizoJogosultsagBundle';
export * from './megbizoKivalasztasRequest';
export * from './megye';
export * from './metadata';
export * from './metadataItem';
export * from './mobilAppFelhasznalo';
export * from './mobilAppVizsgaOsszesito';
export * from './mobilappMobilappfogaskepuploadBody';
export * from './mobilappMobilappkedvenchorgaszhelykepfeltoltesBody';
export * from './orszag';
export * from './personalizedJogosultsag';
export * from './postafiokItem';
export * from './postafiokListazasResponse';
export * from './rogzitesiSzabaly';
export * from './selectItem';
export * from './sssUser';
export * from './szemelyRequest';
export * from './szereploRequest';
export * from './szolgaltatasDetails';
export * from './szolgaltatasListItem';
export * from './szolgaltatasRegisztracioBiralat';
export * from './szolgaltatasRequest';
export * from './szolgaltatoNevjegy';
export * from './telepules';
export * from './teruletiJegy';
export * from './teruletiJegyOnlineSztornoDetails';
export * from './teruletiJegyTermekRequest';
export * from './teruletiKiegeszitoJegy';
export * from './tilalmiIdoszak';
export * from './toroltElektronikusFogas';
export * from './torzsadatBundle';
export * from './uploadFileResponse';
export * from './uzenetKezbesitesRequest';
export * from './vcsVizteruletIds';
export * from './vedettVizterulet';
export * from './versenyzoiEngedely';
export * from './versenyzoiEngedelyRequest';
export * from './vizsgaElozmeny';
export * from './vizteruletCsoportLeiras';
export * from './vizteruletHorgaszat';
export * from './vizteruletTorzsadat';
export * from './vizteruletView';
export * from './vizteruletViewItem';
export * from './vizteruletZarvatartas';
export * from './vizteruletZarvatartasItem';
