<ion-content class="moh-bg-light">
  <ng-container *ngIf="halfajList && vizteruletList; else adatokBetoltese">
    <div [ngClass]="fogasokGroup || selectedVizterulet ? '' : 'vertical-center'">
      <ion-grid>
        <ion-row>
          <ion-col size-md="6" offset-md="3">
            <ng-container *ngIf="enaploNyilvantartas && fogasokGroup; else noContent">
              <ng-container *ngFor="let group of fogasokGroup">
                <h5>{{ group.pkDate | date: 'longDate'}}</h5>
                <ng-container *ngFor="let fogas of group.fogasok">
                  <horgasz-fogas-card
                    [fogas]="fogas"
                    [halfajList]="halfajList"
                    [vizteruletList]="vizteruletList">
                  </horgasz-fogas-card>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-template #noContent>
              <ion-card class="no-bg ion-text-center">
                <img alt="Figyelem" src="assets/icon/warning.svg" class="moh-warning"/>
                <ion-card-header>
                    <ion-card-title>Még nincs fogás feltöltve.</ion-card-title>
                </ion-card-header>
              </ion-card>
            </ng-template>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ng-container>

  <ng-template #adatokBetoltese>
    <horgasz-no-content></horgasz-no-content>
  </ng-template>
</ion-content>

<ion-footer *ngIf="halfajList && vizteruletList" class="fogasok-footer">
  <div class="ion-text-center">
    <ion-button *ngIf="selectedVizterulet" (click)="onFogasRogzitesClick()">
      Új fogás rögzítése
    </ion-button>
  </div>
  <horgasz-last-modified-refresher
    (refresh)="onLastModifierRefreshEvent()"
    [lastModifiedType]="LastModifiedType.FOGASINAPLO">
  </horgasz-last-modified-refresher>
</ion-footer>
