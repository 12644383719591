import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { GPSKoordinata, HorgaszturizmusMapPoi, MobilAppControllerService } from 'api';
import { Utils } from 'app/core/utils';
import { LifeCycleService } from 'app/services/lifecycle.service';
import { ToastService } from 'app/services/toast.service';
import { PoiMarkerData } from 'app/terkep/shared/poi-marker-data';
import { TerkepConstants } from 'app/terkep/shared/terkep-constants';
import { TerkepHelper } from 'app/terkep/shared/terkep-helper';
import * as Leaflet from 'leaflet';
import { Observable, ReplaySubject } from 'rxjs';

@Component({
  selector: 'horgasz-horgaszturizmus-terkep',
  templateUrl: './horgaszturizmus-terkep.component.html',
  styleUrls: ['./horgaszturizmus-terkep.component.scss']
})
export class HorgaszturizmusTerkepComponent extends OnDestroyMixin implements OnInit, OnChanges {

  @Input() poiList: Array<HorgaszturizmusMapPoi>;
  @Input() locationPos: GPSKoordinata;

  @Output() mapClicked: EventEmitter<Leaflet.LatLng> = new EventEmitter();
  @Output() mapReady: EventEmitter<boolean> = new EventEmitter();
  @Output() mapZoomEnd: EventEmitter<Leaflet.LeafletEvent> = new EventEmitter();
  @Output() mapMoveEnd: EventEmitter<Leaflet.LeafletEvent> = new EventEmitter();
  @Output() poiPos: EventEmitter<Leaflet.LatLng> = new EventEmitter();

  mapOptions: Leaflet.MapOptions = TerkepConstants.getDefaultOptions();
  map: Leaflet.Map;
  mapMarkerGroup: Leaflet.MarkerClusterGroup;

  mapMarkers: Array<PoiMarkerData> = [];

  selectedMarker: PoiMarkerData;
  kozelbenCircleLayer: Leaflet.Layer;
  locationMarker: Leaflet.Marker;
  isLoading: boolean;
  locationInitCompleted: boolean;
  private simpleChangesSubject = new ReplaySubject<SimpleChanges>();
  private simpleChangesObservable: Observable<SimpleChanges>;

  constructor(
    private zone: NgZone,
    private toastService: ToastService,
    private mobilAppControllerService: MobilAppControllerService,
    private lifeCycleService: LifeCycleService
  ) {
    super();
    this.simpleChangesObservable = this.simpleChangesSubject.asObservable();
  }

  ngOnInit(): void {
    this.lifeCycleService.didEnter.pipe(untilComponentDestroyed(this)).subscribe(() => {
      if (this.map) {
        TerkepHelper.invalidateSize(this.map);
        // this.locationInitCompleted = false;
        // this.refreshLocationLayer();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.simpleChangesSubject.next(changes);
  }

  async onMapReady(map: Leaflet.Map) {
    this.map = map;
    this.mapMarkerGroup = Leaflet.markerClusterGroup();
    this.map.addLayer(this.mapMarkerGroup);

    //this.map.zoomControl.setPosition('bottomright');
    this.map.on('click', event => {
      const mouseEvent = event as Leaflet.LeafletMouseEvent;
      this.mapClicked.emit(mouseEvent.latlng);
      this.clearMarkerSelection();
    });
    this.mapReady.emit(true);

    //start processing changes
    this.simpleChangesObservable.pipe(untilComponentDestroyed(this)).subscribe(changes => {
      if (changes.poiList) {
        this.refreshPOIs();
      }
      if (changes.locationPos && Utils.hasValue(this.locationPos)) {
        this.refreshLocationLayer();
      }
      TerkepHelper.invalidateSize(this.map);
    });
  }

  onMapZoomEnd(event: Leaflet.LeafletEvent) {
    this.mapZoomEnd.emit(event);
  }

  onMapMoveEnd(event: Leaflet.LeafletEvent) {
    this.mapMoveEnd.emit(event);
  }

  onClosePopup() {
    this.clearMarkerSelection();
  }

  private refreshPOIs(){
    if(this.poiList?.length > 0){
      const mapPoiIds = this.mapMarkers.map(m => m.mapPoi.id);
      this.poiList.filter(poi => !mapPoiIds.includes(poi.id))
        .forEach(poi => {
          const markerData = this.toMarkerData(poi);
          if(markerData){
            this.mapMarkers.push(markerData);
            markerData.marker.addTo(this.mapMarkerGroup);
          }
      });
    }
  }

  private toMarkerData(poi: HorgaszturizmusMapPoi){
    let poiMarkerData: PoiMarkerData;
    if (poi.koordinata) {
      const latLng = new Leaflet.LatLng(poi.koordinata.szelessegiFok, poi.koordinata.hosszusagiFok);
      const marker = new Leaflet.Marker(latLng, {
        title: poi.megnevezes,
        icon: this.createIcon(poi, false),
      }).on('click', () => {
        this.zone.run(() => {
          this.clearMarkerSelection();
          this.selectMarker(marker);
        });
      });
      poiMarkerData = { marker, mapPoi: poi };
    } else {
      console.log(['Koordináta nélküli poi!', poi]);
    }
    return poiMarkerData;
  }

  private createIcon(poi: HorgaszturizmusMapPoi, selected: boolean): Leaflet.Icon {
    return new Leaflet.Icon({
      iconUrl: TerkepConstants.getPoiIconUrl(poi.type),
      iconSize: selected ? [57, 80] : [38, 53],
      iconAnchor: selected ? [28, 80] : [19, 53],
    });
  }

  private clearMarkerSelection() {
    if (this.selectedMarker) {
      this.selectedMarker.marker.setIcon(this.createIcon(this.selectedMarker.mapPoi, false));
      this.selectedMarker = undefined;
    }
  }

  private selectMarker(marker: Leaflet.Marker) {
    this.selectedMarker = undefined;
    this.selectedMarker = this.mapMarkers.find(item => item.marker === marker);
    marker.setIcon(this.createIcon(this.selectedMarker.mapPoi, true));
    //this.map.panTo(marker.getLatLng());
    if (this.selectedMarker.mapPoi) {
      this.fetchPoiItemData(this.selectedMarker);
    }
  }

  private fetchPoiItemData(selectedMarker: PoiMarkerData) {
    this.isLoading = true;
    this.mobilAppControllerService.fetchById(selectedMarker.mapPoi.id).toPromise()
      .then(horgaszturizmusPoiItem => selectedMarker.poiItem = horgaszturizmusPoiItem)
      .catch(() => {}).finally(() => this.isLoading = false);
  }

  private refreshLocationLayer() {
    // lokáció marker
    if (this.locationMarker) {
      this.locationMarker.removeFrom(this.map);
    }
    this.locationMarker = TerkepHelper.createLocationMarker(this.locationPos);
    this.locationMarker.addTo(this.map);
    // közelben kör
    if (this.kozelbenCircleLayer) {
      this.kozelbenCircleLayer.removeFrom(this.map);
    }
    this.kozelbenCircleLayer = Leaflet.circle(this.locationMarker.getLatLng(), TerkepConstants.DEFAULT_LAYER_RADIUS);
    this.kozelbenCircleLayer.addTo(this.map);

    if (!this.locationInitCompleted) {
      this.map.setView(this.locationMarker.getLatLng(), TerkepConstants.ZOOM_LEVEL_KOZELBEN);
      this.locationInitCompleted = true;
    }
  }
}
