import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { FogasDatePipe } from 'app/altalanos/pipes/fogas-date.pipe';
import { NonBreakingHyphenPipe } from 'app/altalanos/pipes/non-breaking-hyphen.pipe';
import { VizteruletPipe } from 'app/altalanos/pipes/vizterulet.pipe';
import { VersionComponent } from 'app/altalanos/version/version.component';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';

import { KapcsolatPageRoutingModule } from './kapcsolat-routing.module';

import { KapcsolatPage } from './kapcsolat.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    KapcsolatPageRoutingModule,
    CoreModule,
    FogasDatePipe,
    NonBreakingHyphenPipe,
    VizteruletPipe,
    SharedModule,
    VersionComponent
  ],
  declarations: [KapcsolatPage]
})
export class KapcsolatPageModule {}
