import { Injectable } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MobilAppControllerService, MobilAppFelhasznalo, PostafiokItem, PostafiokListazasResponse, UzenetKezbesitesRequest } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { LocalStoreKey } from 'app/core/local-store-key';
import { TimerMutex } from 'app/core/timer-mutex';
import { Utils } from 'app/core/utils';
import { Mutex } from 'async-mutex';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class PostafiokService extends OnDestroyMixin {

  felhasznalo: MobilAppFelhasznalo;
  kezbesitetlenUzenetekSzama: Observable<number>;
  uzenetek: Observable<PostafiokListazasResponse>;

  private kezbesitetlenUzenetekSzamaSubject: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  private uzenetekSubject: BehaviorSubject<PostafiokListazasResponse> = new BehaviorSubject<PostafiokListazasResponse>(undefined);

  private pollInterval: number = 10 * 60 * 1000; // 10 perc
  private idozitoSubs: Subscription;

  private serverRefreshTimerMutex = new TimerMutex(3);
  private idozitoMutex = new Mutex();

  constructor(
    private cacheService: CacheService,
    private authService: AuthService,
    private mobilappControllerService: MobilAppControllerService,
  ) {
    super();
    this.kezbesitetlenUzenetekSzama = this.kezbesitetlenUzenetekSzamaSubject.asObservable().pipe(
      filter(result => Utils.hasValue(result))
    );
    this.uzenetek = this.uzenetekSubject.asObservable().pipe(
      filter(result => Utils.hasValue(result))
    );
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(result => {
      this.felhasznalo = result.felhasznalo;
      if (this.felhasznalo) {
        this.startListeningKezbesitetlenUzenetek();
      } else {
        this.emptyKezbesitetlenUzenetekSzama();
      }
    });
  }

  refresh() {
    if (this.felhasznalo) {
      this.startListeningKezbesitetlenUzenetek();
    }
  }

  async refreshUzenetList() {
    this.cacheService.cache<PostafiokListazasResponse>(this.mobilappControllerService.listazas(), LocalStoreKey.POSTAFIOK_LISTAZAS)
      .subscribe({
        next: result => this.uzenetekSubject.next(result),
        error: () => {}
      });
  }

  uzenetKezbesites(uzenet: PostafiokItem) {
    const request: UzenetKezbesitesRequest = {uzenetId: uzenet.sourceId};
    this.sendUzenetKezbesites(request);
  }

  uzenetValasz(uzenet: PostafiokItem, valasz: boolean) {
    const request: UzenetKezbesitesRequest = {uzenetId: uzenet.sourceId, cimzettValasz: valasz};
    this.sendUzenetKezbesites(request);
  }

  getUzenet(sourceId: number) {
    const uzenetek = this.uzenetekSubject.getValue();
    if(uzenetek){
      return uzenetek?.olvasottUzenetList.find(uzenet => uzenet.sourceId === sourceId) ??
        uzenetek?.olvasatlanUzenetList.find(uzenet => uzenet.sourceId === sourceId);
    } else {
      this.refreshUzenetList();
    }
  }

  private sendUzenetKezbesites(request: UzenetKezbesitesRequest){
    this.mobilappControllerService.kezbesites(request).subscribe({
      next: () => {
        this.refreshUzenetList();
        this.refresh();
      },
      error: () => {}
    });
  }

  private emptyKezbesitetlenUzenetekSzama() {
    this.idozitoSubs.unsubscribe();
    this.kezbesitetlenUzenetekSzamaSubject.next(undefined);
  }

  private startListeningKezbesitetlenUzenetek() {
    this.serverRefreshTimerMutex.runExclusive(() => {
      //mindig egy unsubscribe + subscribe
      this.idozitoMutex.runExclusive(() => {
        this.idozitoSubs?.unsubscribe();
        this.idozitoSubs = timer(0, this.pollInterval).pipe(untilComponentDestroyed(this)).subscribe(() => {
          this.cacheService.cache<number>(this.mobilappControllerService.nemKezbesitettSzamossag(), LocalStoreKey.POSTAFIOK_SZAMOSSAG)
            .subscribe({
              next: result => this.kezbesitetlenUzenetekSzamaSubject.next(result),
              error: () => {}
            });
        });
      });
    });
  }
}
