import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'nonBreakingHyphen',
  standalone: true
})
export class NonBreakingHyphenPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  transform(value: string | number): SafeHtml | number {
    return typeof value === 'string' ? this.sanitizer.bypassSecurityTrustHtml(value.replace(/-/g, '&#8209;')) : value;
  }
}
