import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { FogasDatePipe } from 'app/altalanos/pipes/fogas-date.pipe';
import { AktualisHorgaszatCardComponent } from 'app/fogasi-naplo/horgasznapok/aktualis-horgaszat-card/aktualis-horgaszat-card.component';

import { HorgasznapokPageRoutingModule } from 'app/fogasi-naplo/horgasznapok/horgasznapok-routing.module';

import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from 'app/core/custom-date-adapter.service';
import { MaterialModule } from 'app/material/material.module';
import { SharedModule } from 'app/shared/shared.module';
import { HorgasznapokPage } from 'app/fogasi-naplo/horgasznapok/horgasznapok-page.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HorgasznapokPageRoutingModule,
    MaterialModule,
    SharedModule,
    FogasDatePipe
  ],
  declarations: [
    HorgasznapokPage,
    AktualisHorgaszatCardComponent,
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter},
  ]
})
export class HorgasznapokPageModule {}
