<ion-card class="horgaszat-card" *ngIf="aktualisHorgaszatData?.selectedVizterulet">
  <ion-card-header>
    <ion-item lines="none">
      <ion-label class="ion-text-wrap">
        <ion-card-title>Aktuális horgászat</ion-card-title>
        <ion-card-subtitle>
          Horgászat kezdete: {{aktualisHorgaszatData.horgaszatKezdete | fogasDate: 'timestamp'}}
        </ion-card-subtitle>
        <p>{{aktualisHorgaszatData.selectedVizterulet.nev}}</p>
        <p *ngIf="aktualisHorgaszatData.selectedVizterulet.nyilvantartas === 'HORINFO'" style="margin-top: 8px">
          Víztérkód: <b>{{aktualisHorgaszatData.selectedVizterulet.vizterkod}}</b>
        </p>
        <div *ngIf="aktualisHorgaszatData.selectedVizterulet.nyilvantartas === 'NEM_NYILVANTARTOTT'" class="vt-warning">
          <ion-icon src="/assets/icon/warning.svg"></ion-icon> Kizárólag idegenhonos halfajok tarthatóak meg
        </div>
      </ion-label>
    </ion-item>
  </ion-card-header>
  <ion-card-content>
    <div class="ion-text-center">
      <ion-button fill="outline" (click)="onBefejezes()">
        Befejezés
      </ion-button>
      <ion-button style="padding-left: 20px" fill="outline" (click)="onUjVizterulet()">
        Új vízterület
      </ion-button>
    </div>
  </ion-card-content>
</ion-card>
