import { AddFogasRequest, ElektronikusFogas, ElektronikusFogasNyilvantartas, Halfaj, RogzitesiSzabaly, TilalmiIdoszak, VizteruletTorzsadat } from 'api';
import { Utils } from 'app/core/utils';
import { FogasValidacioResult } from 'app/fogasi-naplo/fogasok/rogzites-modal/fogas-validacio-result';
import * as moment from 'moment';
import { FogasValidacioResponse } from './fogas-validacio-response';

export class FogasRogzitesModalData {
  fogasiNaploId: string;
  ervenyessegKezdete: string;
  ervenyessegVege: string;
  halfajList: Array<Halfaj>;
  selectedVizterulet: VizteruletTorzsadat;
  fogasok: Array<ElektronikusFogas>;

  constructor(
    fogasiNaploId: string,
    eFogasNyilvantartas: ElektronikusFogasNyilvantartas,
    halfajList: Array<Halfaj>,
    selectedVizterulet: VizteruletTorzsadat
  ) {
    this.fogasiNaploId = fogasiNaploId;
    this.fogasok = eFogasNyilvantartas.fogasok;
    this.ervenyessegKezdete = eFogasNyilvantartas.ervenyessegKezdete;
    this.ervenyessegVege = eFogasNyilvantartas.ervenyessegVege;
    this.halfajList = halfajList;
    this.selectedVizterulet = selectedVizterulet;
  }

  getHalfajNev(halfajId: number) {
    return this.halfajList.find(halfaj => halfaj.id === halfajId)?.nev;
  }

  validate(request: AddFogasRequest): FogasValidacioResponse {
    const halfaj = this.halfajList.find(h => h.id === request.halfajId);
    const todayFogasok = this.fogasok.filter(f =>
      Utils.equalsDate(f.idopont, request.idopont)
    );

    const validacioResponse = {
      fogasValidacioResultList: [],
      rogzitesEngedelyezett: true,
      rogzitesiSzabaly: halfaj.rogzitesiSzabaly,
      tilalmiIdoszak: undefined
    } as FogasValidacioResponse;

    const rogzitesiSzabaly = halfaj.rogzitesiSzabaly;

    //tilalmi idoszak
    if(rogzitesiSzabaly.tilalmiIdoszakVedett){
      const tilalmiIdoszak = this.getTilalmiIdoszak(request.idopont, rogzitesiSzabaly);
      if (tilalmiIdoszak) {
        validacioResponse.tilalmiIdoszak = tilalmiIdoszak;
        validacioResponse.fogasValidacioResultList.push(FogasValidacioResult.TILALMI_IDOSZAK);
      }
    }

    //napi darab vedett
    if(rogzitesiSzabaly.napiDarabVedett){
      if (Utils.hasValue(rogzitesiSzabaly.napiDarabLimit)) {
        const napiFogasok = todayFogasok
          .filter(f => f.halfajId === request.halfajId);
        if(napiFogasok.length >= rogzitesiSzabaly.napiDarabLimit){
          validacioResponse.fogasValidacioResultList.push(FogasValidacioResult.NAPI_DB_TULLEPES);
          validacioResponse.rogzitesEngedelyezett = false;
        }
      }
      //max napi darabszammal vedett azonos honos halfajok
      if(Utils.hasValue(rogzitesiSzabaly.maxNapiDarabLimit)) {
        const azonosHonosHalfajIds = this.halfajList
          .filter(h => h.honossag === halfaj.honossag)
          .filter(h => Utils.hasValue(h.rogzitesiSzabaly.maxNapiDarabLimit))
          .map(h => h.id);
        const azonosHonosFogasok = todayFogasok
          .filter(h => azonosHonosHalfajIds.includes(h.halfajId));
        if(azonosHonosFogasok.length >= rogzitesiSzabaly.maxNapiDarabLimit) {
          validacioResponse.fogasValidacioResultList.push(FogasValidacioResult.NAPI_MAXDB_TULLEPES);
          validacioResponse.rogzitesEngedelyezett = false;
        }
      }
    }

    //napi suly vedett
    if(rogzitesiSzabaly.napiSulyVedett){
      if (Utils.hasValue(rogzitesiSzabaly.napiSulyLimit)) {
        const napiFogasokSuly = todayFogasok
          .filter(f => f.halfajId === request.halfajId)
          .map(h => h.suly)
          .reduce((a, b) => a + b, 0);
        //beleszamoljuk a rogzitendo sulyt is?
        if(napiFogasokSuly >= rogzitesiSzabaly.napiSulyLimit){
          validacioResponse.fogasValidacioResultList.push(FogasValidacioResult.NAPI_SULY_TULLEPES);
          validacioResponse.rogzitesEngedelyezett = false;
        }
      }
      //max napi suly vedett azonos honos halfajok
      if(Utils.hasValue(rogzitesiSzabaly.maxNapiSulyLimit)) {
        const azonosHonosHalfajIds = this.halfajList
          .filter(h => h.honossag === halfaj.honossag)
          .filter(h => Utils.hasValue(h.rogzitesiSzabaly.maxNapiSulyLimit))
          .map(h => h.id);
        const azonosHonosFogasokSuly = todayFogasok
          .filter(h => azonosHonosHalfajIds.includes(h.halfajId))
          .map(h => h.suly)
          .reduce((a, b) => a + b, 0);
        if(azonosHonosFogasokSuly >= rogzitesiSzabaly.maxNapiSulyLimit) {
          validacioResponse.fogasValidacioResultList.push(FogasValidacioResult.NAPI_MAXSULY_TULLEPES);
          validacioResponse.rogzitesEngedelyezett = false;
        }
      }
    }

    // rogzitesi suly min max limit
    if (rogzitesiSzabaly.sulyLimitMin && rogzitesiSzabaly.sulyLimitMax &&
      (request.suly < rogzitesiSzabaly.sulyLimitMin || request.suly > rogzitesiSzabaly.sulyLimitMax)) {
      validacioResponse.fogasValidacioResultList.push(FogasValidacioResult.SULY_LIMITEN_KIVUL);
      validacioResponse.rogzitesEngedelyezett = false;
    }

    return validacioResponse;
  }

  private getTilalmiIdoszak(idopont: Date, rogzitesiSzabaly: RogzitesiSzabaly): TilalmiIdoszak {
    if (idopont && rogzitesiSzabaly.tilalmiIdoszakok.length > 0) {
      return rogzitesiSzabaly.tilalmiIdoszakok.find(tilalmiIdoszak => {
        const momentIdopont = moment(idopont);
        const momentTilalmiIdoszakKezdete = moment(tilalmiIdoszak.kezdete);
        const momentTilalmiIdoszakVege = moment(tilalmiIdoszak.vege);
        return !momentIdopont.isBefore(momentTilalmiIdoszakKezdete) && !momentIdopont.isAfter(momentTilalmiIdoszakVege);
      });
    } else {
      return undefined;
    }
  }
}
