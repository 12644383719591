import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';

@Component({
  selector: 'horgasz-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss'],
})
export class SimpleHeaderComponent extends OnDestroyMixin implements OnInit {

  @Input() title: string;
  @Input() backButton: boolean;
  @Input() notifications = true;

  constructor(
    private navController: NavController
  ) {
    super();
  }

  ngOnInit() {}

  onLocationBack() {
    this.navController.back({
      animated: true,
      animationDirection: 'back',
    });
  }

}
