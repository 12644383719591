import { Component, OnInit } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { EnaploNyilatkozatRequest, MobilAppControllerService, MobilAppFelhasznalo } from 'api';
import { AuthService } from 'app/auth/auth.service';
import { ConfirmationDialogData } from 'app/core/confirmation-dialog/confirmation-dialog-data';
import { ConfirmationDialogService } from 'app/core/confirmation-dialog/confirmation-dialog.service';
import { NyilatkozatokStep } from 'app/horgasz-engedelyek/nyilatkozatok/nyilatkozatok-step';

@Component({
  selector: 'horgasz-nyilatkozatok',
  templateUrl: './nyilatkozatok.page.html',
  styleUrls: ['./nyilatkozatok.page.scss'],
})
export class NyilatkozatokPage extends OnDestroyMixin implements OnInit {

  NyilatkozatokStep = NyilatkozatokStep;

  felhasznalo: MobilAppFelhasznalo;
  step = NyilatkozatokStep.INFO;

  checkBoxEnaploEngedelyezes = false;
  checkBoxUtmutatoMegertes = false;

  serverError: any;

  submitted = false;

  constructor(
    private authService: AuthService,
    private mobilAppControllerService: MobilAppControllerService,
    private confirmationDialogService: ConfirmationDialogService,
  ) {
    super();
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
        this.felhasznalo = auth.felhasznalo;
      });
  }

  ngOnInit() {
  }

  ionViewDidEnter(){
    this.onEnaploEngedelyezesBezaras();
  }

  onStartEnaploEngedelyezes() {
    this.step = NyilatkozatokStep.UTMUTATO;
  }

  async onEnaploEngedelyezesVisszavonas() {
    const data: ConfirmationDialogData = {
      messages: ['Biztosan visszavonod az e-napló váltási lehetőséget?']
    };
    if(await this.confirmationDialogService.openFigyelmeztetesDialog(data)){
      this.checkBoxEnaploEngedelyezes = false;
      await this.onEnaploEngedelyezesMentes();
    }
  }

  onEnaploEngedelyezesVeglegesites() {
    this.step = NyilatkozatokStep.ENGEDELYEZES;
  }

  async onEnaploEngedelyezesMentes() {
    this.submitted = true;
    const request: EnaploNyilatkozatRequest = {
      szemelyId: this.felhasznalo.szemelyId,
      engedelyezett: this.checkBoxEnaploEngedelyezes
    };
    try {
      await this.mobilAppControllerService.enaploNyilatkozat(request).toPromise();
      await this.authService.refreshAuthenticationFromServer();
      this.felhasznalo.enaploEngedelyezett = request.engedelyezett;
      this.onEnaploEngedelyezesBezaras();
      this.submitted = false;
    } catch (err) {
      this.serverError = err;
    } finally {
      this.submitted = false;
    }
  }

  onEnaploEngedelyezesBezaras() {
    this.serverError = null;
    this.checkBoxEnaploEngedelyezes = false;
    this.checkBoxUtmutatoMegertes = false;
    this.step = NyilatkozatokStep.INFO;
  }

  onUtmutatoMegjelenites(){
    if(!this.submitted){
      this.step = NyilatkozatokStep.UTMUTATO;
    }
  }
}
