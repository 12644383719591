import { Component } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MobilAppControllerService, VersenyzoiEngedelyRequest } from 'api';
import { VersenyzoiEngedely } from 'api/model/versenyzoiEngedely';
import { AuthService } from 'app/auth/auth.service';
import { Utils } from 'app/core/utils';

@Component({
  selector: 'horgasz-versenyzoi-engedely',
  templateUrl: './versenyzoi-engedely.page.html',
  styleUrls: ['../jegyek/jegyek.page.scss'],
})
export class VersenyzoiEngedelyPage extends OnDestroyMixin {

  szemelyId: number;
  versenyzoiEngedely: VersenyzoiEngedely;
  ervenyessegiEv = new Date().getFullYear();
  VersenyzoiEngedelyStatuszEnum = VersenyzoiEngedely.StatuszEnum;

  constructor(
    private mobilAppControllerService: MobilAppControllerService,
    protected authService: AuthService,
  ) {
    super();
    this.authService.authentication.pipe(untilComponentDestroyed(this)).subscribe(auth => {
      this.versenyzoiEngedely = auth.felhasznalo?.ervenyesVersenyzoiEngedely;
      this.szemelyId = auth.felhasznalo?.szemelyId;
    });
  }

  onIgazolasLetoltes(){
    const request = {
      szemelyId: this.szemelyId,
      ervenyessegiEv: this.versenyzoiEngedely.ervenyessegiEv
    } as VersenyzoiEngedelyRequest;
    this.mobilAppControllerService.versenyzoiEngedelyNyomtatas(request).toPromise()
      .then(result => {
        const filename = 'versenyzoi_engedely_' + this.versenyzoiEngedely.ervenyessegiEv + '.pdf';
        Utils.openBase64Content(result, filename);
      }).catch(() => {});
  }
}
