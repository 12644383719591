import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'horgasz-kapcsolat',
  templateUrl: './kapcsolat.page.html',
  styleUrls: ['./kapcsolat.page.scss'],
})
export class KapcsolatPage implements OnInit {

  mohoszAddress = '1124 Budapest Korompai utca 17.';

  constructor() { }

  ngOnInit() {
  }

  onAddressClick() {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(this.mohoszAddress)}`;
    window.open(url, '_blank');
  }
}
