import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'horgasz-utmutato',
  templateUrl: './utmutato.component.html',
  styleUrls: ['./utmutato.component.scss'],
})
export class UtmutatoComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
